import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import style from "themes/styles";
import { withStyles } from "@mui/styles";
import { Stack } from "@mui/system";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import ProgressBar from "Components/ProgressBar";

import { GET_PARAMS, GET_ENUMS } from "PopupRoutes/components/settings";
import usePrepareLink from "hooks/usePrepareLink";
import { useNavigate } from "react-router-dom";

const SelectGender = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const nextPage = usePrepareLink({
    query: {
      [GET_PARAMS.popup]: GET_ENUMS.popup.ethnicity,
    },
  });
  const prvPage = usePrepareLink({
    query: {
      [GET_PARAMS.popup]: GET_ENUMS.popup.gender,
    },
  });
  const HandleNext = () => {
    navigate(nextPage);
  };

  const HandleBack = () => {
    navigate(prvPage);
  };

  return (
    <>
      <DialogTitle>
        <ProgressBar value={25} />
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <Box my={3}>
          <Typography
            variant="subtitle2"
            color="secondary"
            fontWeight="500"
            mb={1}
          >
            Lets setup your profile!
          </Typography>
          <Typography color="primary" variant="subtitle1" fontWeight="700">
            My birthday is
          </Typography>
        </Box>
        <Stack spacing={3} mx={3} mb={3}>
          <TextField type="date" />
          <Stack direction="row" justifyContent="center" spacing={4}>
            <Box
              component={"img"}
              src={"/images/virgo.png"}
              alt={"image"}
              sx={{
                height: "137px",
                width: "137px",
              }}
            />
          </Stack>
          <Typography
            sx={{ textDecoration: "underline" }}
            variant="h6"
            className={classes.gradientText}
          >
            Amazing you are a libra!
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack flex={1} justifyContent="space-between" direction="row">
          <Button onClick={() => HandleBack()} className={classes.backBtn}>
            Back
          </Button>
          <Button onClick={() => HandleNext()} className={classes.nextBtn}>
            Next
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};

SelectGender.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(SelectGender);
