import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import style from "themes/styles";
import { withStyles } from "@mui/styles";

const Button1 = styled("button")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "black",
  background: "transparent",
  height: "48px",
  fontSize: "16px",
  border: "2px solid #F4F4FF",
}));

const Button2 = styled("button")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
  height: "48px",
  fontSize: "16px",
  border: "2px solid #F4F4FF",
  color: "white",
  borderRadius: "10px",
}));

function Card1(props) {
  return (
    <Card>
      <CardMedia
        component="img"
        sx={{
          height: "200px",
          width: "280px",
          margin: "0 auto",
        }}
        image="/images/Group 22734.png"
        alt="Paella dish"
      />
      <CardContent>
        <Typography variant="subtitle1" fontWeight="500" color="primary.dark2">
          Lindsey Levin,20
        </Typography>
        <Typography
          variant="body2"
          fontWeight="500"
          mb={1}
          color="primary.dark2"
        >
          @Lindsey
        </Typography>
        <Typography variant="body1" color="gray.main">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum
          dolor sit amet, consectetur adipiscing elit.
        </Typography>
      </CardContent>
      <CardActions>
        <Button1 variant="outlined" sx={{ width: 1 }}>
          Reject
        </Button1>
        <Button2 variant="outlined" sx={{ width: 1 }}>
          Reject
        </Button2>
      </CardActions>
    </Card>
  );
}

Card1.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Card1);
