import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { withStyles } from "@mui/styles";
import style from "themes/appStyles";
import { useEffect } from "react";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#F9F6FF",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};
function SuccessModal(props) {
  const { modalOpen, closeModal, classes } = props;

  useEffect(() => {
    setTimeout(() => {
      localStorage.setItem("plan", true);
      window.location.reload(false);
      window.location.href = "/app/setting/plan";
    }, 3000);
  }, []);

  return (
    <Modal
      open={modalOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography
          className={classes.gradientText}
          variant="h6"
          component="h2"
          fontWeight={700}
          mb={1}
        >
          Success
        </Typography>
        <Typography
          variant="h6"
          component="h2"
          color="primary.dark2"
          fontWeight={700}
        >
          Your Plan is Upgrade
        </Typography>
      </Box>
    </Modal>
  );
}
export default withStyles(style)(SuccessModal);
