import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import style from "themes/styles";
import {
  Box,
  Drawer,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { filterIcon } from "assets/images";
import CloseIcon from "@mui/icons-material/Close";

const drawerWidth = 350;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

function Filter(props) {
  const { classes } = props;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          zIndex: 1100,
          right: 0,
          top: 46,
          cursor: "pointer",
        }}
      >
        <img
          style={{ width: 45, height: 35 }}
          alt="filter"
          src={filterIcon}
          onClick={() => handleDrawerOpen()}
        />
      </Box>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <Typography
            variant="subtitle1"
            color="primary.dark2"
            fontWeight={500}
            ml={3}
          >
            Filter
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box
          sx={{
            padding: "30px",
            boxSizing: "border-box",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
          className={classes.chatBox}
        >
          <Stack spacing={2}>
            <Stack>
              <Typography
                variant="body1"
                color="primary.dark2"
                fontWeight={500}
                mb={1.5}
              >
                Location
              </Typography>
              <TextField
                className={classes.textField}
                placeholder="Select Location"
                select
                sx={{ width: "100%", background: "white" }}
              >
                <MenuItem value="Hetrosexual">Hetrosexual</MenuItem>
              </TextField>
            </Stack>
            <Stack>
              <Typography
                variant="body1"
                color="primary.dark2"
                fontWeight={500}
                mb={1.5}
              >
                Ethnicity
              </Typography>
              <TextField
                className={classes.textField}
                placeholder="African Americans"
                select
                sx={{ width: "100%", background: "white" }}
              >
                <MenuItem value="Hetrosexual">African Americans</MenuItem>
              </TextField>
            </Stack>
            <Stack>
              <Typography
                variant="body1"
                color="primary.dark2"
                fontWeight={500}
                mb={1.5}
              >
                Sexual orientation
              </Typography>
              <TextField
                className={classes.textField}
                placeholder="Select Location"
                select
                sx={{ width: "100%", background: "white" }}
              >
                <MenuItem value="Hetrosexual">Hetrosexual</MenuItem>
              </TextField>
            </Stack>
            <Stack>
              <Typography
                variant="body1"
                color="primary.dark2"
                fontWeight={500}
                mb={1.5}
              >
                Organizational Affiliation
              </Typography>
              <TextField
                className={classes.textField}
                placeholder="Select Location"
                select
                sx={{ width: "100%", background: "white" }}
              >
                <MenuItem value="Hetrosexual">Hetrosexual</MenuItem>
              </TextField>
            </Stack>
            <Stack>
              <Typography
                variant="body1"
                color="primary.dark2"
                fontWeight={500}
                mb={1.5}
              >
                Organizational Affiliation
              </Typography>
              <TextField
                className={classes.textField}
                placeholder="Select Location"
                sx={{ width: "100%", background: "white" }}
              />
            </Stack>
            <Typography
              className={classes.blue}
              variant="body2"
              color="primary.dark2"
              sx={{ textDecoration: "underline" }}
            >
              + Add another location
            </Typography>
            <Stack>
              <Button
                className={classes.gradientBtn}
                sx={{
                  maxWidth: "100%",
                  maxHeight: 45,
                  fontSize: "14px !important",
                  mb: 2,
                }}
              >
                Save Filter
              </Button>
              <Button
                className={classes.gradientText}
                sx={{ textTransform: "capitalize", fontWeight: "500" }}
              >
                Clear filter
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
}

export default withStyles(style)(Filter);
