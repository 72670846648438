import {
  Button,
  Grid,
  IconButton,
  styled,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { withStyles } from "@mui/styles";
import style from "themes/styles";
import MenuIcon from "@mui/icons-material/Menu";
import SettingSidebar from "./SettingSidebar";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 45,
  height: 23,
  padding: 0,
  borderRadius: 100,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(21px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        background:
          theme.palette.mode === "dark"
            ? "#177ddc"
            : "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 18,
    height: 18,
    borderRadius: 100,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const AppSetting = (props) => {
  const { classes } = props;
  const [showPhoneInput, setShowPhoneInput] = React.useState(false);
  const [showEmail, setShowEmail] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = (props) => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box>
      <Grid container alignItems="center">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="subtitle1" fontWeight={600} color="primary.dark2">
          App Setting
        </Typography>
        <SettingSidebar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      </Grid>
      <Stack
        sx={{
          maxWidth: "500px",
          gap: "20px",
        }}
      >
        <Box
          style={{
            padding: "13px 15px",
            border: "1px solid #E4E3E3",
            borderRadius: "10px",
            cursor: "pointer",
          }}
        >
          <Box
            onClick={() => {
              showPhoneInput == true
                ? setShowPhoneInput(false)
                : setShowPhoneInput(true);
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body1" color="blue.main">
              Change Phone Number
            </Typography>
            {showPhoneInput ? (
              <ExpandLessIcon color="blue.main" />
            ) : (
              <KeyboardArrowDownIcon color="blue.main" />
            )}
          </Box>
          {showPhoneInput && (
            <Box sx={{ mt: 2, transition: "maxHeight 2s, overflow 2s 2s" }}>
              <TextField
                className={classes.textField}
                fullWidth
                placeholder="Enter Phone Number"
              />
              <Button
                sx={{ maxHeight: 40, mt: 2 }}
                className={classes.gradientBtn}
              >
                Update
              </Button>
            </Box>
          )}
        </Box>
        <Box
          style={{
            padding: "13px 15px",
            border: "1px solid #E4E3E3",
            borderRadius: "10px",
            cursor: "pointer",
          }}
        >
          <Box
            onClick={() => {
              showEmail == true ? setShowEmail(false) : setShowEmail(true);
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body1" color="blue.main">
              Change Email
            </Typography>
            {showEmail ? (
              <ExpandLessIcon color="blue.main" />
            ) : (
              <KeyboardArrowDownIcon color="blue.main" />
            )}
          </Box>
          {showEmail && (
            <Box sx={{ mt: 2 }}>
              <TextField
                className={classes.textField}
                fullWidth
                placeholder="Enter Email"
              />
              <Button
                sx={{ maxHeight: 40, mt: 2 }}
                className={classes.gradientBtn}
              >
                Update
              </Button>
            </Box>
          )}
        </Box>
        <Stack
          sx={{
            padding: "13px 15px",
            border: "1px solid #E4E3E3",
            borderRadius: "10px",
          }}
          justifyContent={"space-between"}
          direction="row"
          spacing={1}
          alignItems="center"
          fullWidth
        >
          <Typography color={"#0A3474"}>Mode (Dark/Light)</Typography>
          <AntSwitch
            defaultChecked
            inputProps={{ "aria-label": "ant design" }}
          />
        </Stack>
        <Stack
          sx={{
            padding: "13px 15px",
            border: "1px solid #E4E3E3",
            borderRadius: "10px",
          }}
          justifyContent={"space-between"}
          direction="row"
          spacing={1}
          alignItems="center"
          fullWidth
        >
          <Typography color={"#0A3474"}>Push Notifications</Typography>
          <AntSwitch
            defaultChecked
            inputProps={{ "aria-label": "ant design" }}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default withStyles(style)(AppSetting);
