import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import style from "themes/styles";
import { withStyles } from "@mui/styles";
import { Stack } from "@mui/system";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  InputBase,
  Typography,
} from "@mui/material";
import ProgressBar from "Components/ProgressBar";

import { GET_PARAMS, GET_ENUMS } from "PopupRoutes/components/settings";
import usePrepareLink from "hooks/usePrepareLink";
import { useNavigate } from "react-router-dom";

const About = (props) => {
  const { classes } = props;
  const [textCount, setTextCount] = React.useState("0");
  const [about, setAbout] = React.useState("");

  const GetCountText = (e) => {
    let TotalText = e.target.value.length;
    setTextCount(TotalText);
    setAbout(e.target.value);
  };

  const navigate = useNavigate();
  const nextPage = usePrepareLink({
    query: {
      [GET_PARAMS.popup]: GET_ENUMS.popup.education,
    },
  });
  const prvPage = usePrepareLink({
    query: {
      [GET_PARAMS.popup]: GET_ENUMS.popup.relationship,
    },
  });
  const HandleNext = () => {
    navigate(nextPage);
  };

  const HandleBack = () => {
    navigate(prvPage);
  };

  return (
    <>
      <DialogTitle>
        <ProgressBar value={65} />
      </DialogTitle>
      <DialogContent>
        <Box my={3} textAlign="center">
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => HandleNext()}
              variant="text"
              className={classes.skipBtn}
            >
              Skip
            </Button>
          </Box>
          <Typography
            color="secondary"
            variant="subtitle2"
            fontWeight="500"
            mb={1}
          >
            Lets setup your profile!
          </Typography>
        </Box>
        <Stack spacing="10px" mx={3} mb={3}>
          <Typography
            sx={{ textAlign: "start" }}
            color="primary"
            variant="subtitle1"
            fontWeight={700}
          >
            About
          </Typography>
          <InputBase
            multiline
            minRows={6}
            maxRows={6}
            className={classes.commentBox}
            inputProps={{ maxLength: 50 }}
            placeholder="Write here about yourself......"
            onChange={(e) => GetCountText(e)}
          />
          <Typography
            sx={{ textAlign: "end" }}
            className={classes.gray}
            variant="subtitle2   "
            fontWeight={400}
          >
            {textCount}/50
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack flex={1} justifyContent="space-between" direction="row">
          <Button onClick={() => HandleBack()} className={classes.backBtn}>
            Back
          </Button>
          <Button onClick={() => HandleNext()} className={classes.nextBtn}>
            Next
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};

About.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.any,
};

export default withStyles(style)(About);
