import { createStore } from "redux";

import reducer from "./reducer";

const Stores = () => {

  /* const middlewares = applyMiddleware();
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducer, composeEnhancers(middlewares)); */

  const store = createStore(reducer);

  const persister = "Blerdy";
  return { store, persister };
};
export default Stores;
