import React from "react";
import PropTypes from "prop-types";
import { Stack, Grid, Box, Button, Paper } from "@mui/material";

import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import SubdirectoryArrowLeftOutlinedIcon from "@mui/icons-material/SubdirectoryArrowLeftOutlined";
import SpaceBarOutlinedIcon from "@mui/icons-material/SpaceBarOutlined";
import IconButton from "@mui/material/IconButton";
import MoreVertTwoToneIcon from "@mui/icons-material/MoreVertTwoTone";
import WhatshotRoundedIcon from "@mui/icons-material/WhatshotRounded";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import style from "themes/styles";
import { withStyles } from "@mui/styles";
import TabPanel from "Components/RightBar/TabPanel";
import classNames from "classnames";

const Home = (props) => {
  const { classes } = props;
  return (
    <Grid container>
      <Grid item xs={12} sm={3}>
        {/*  <Paper elevation={0} square>
          <TabPanel />
        </Paper> */}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper sx={{ m: 2, p: 1 }} elevation={0}>
          <Box
            className={classes.roundedImg}
            component="img"
            alt="Image"
            src="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&w=350&dpr=2"
          />
          <Stack
            sx={{ marginTop: { sm: "-50px", xs: "-35px" } }}
            alignItems="center"
            direction="column"
            justifyContent="center"
            spacing={1}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <IconButton
                className={classNames(
                  classes.avatarGrayBg,
                  classes.mediumAvatar
                )}
              >
                <MoreVertTwoToneIcon />
              </IconButton>
              <IconButton
                className={classNames(
                  classes.avatarGrayBg,
                  classes.smallAvatar
                )}
              >
                <DeleteOutlinedIcon />
              </IconButton>
              <IconButton
                className={classNames(
                  classes.avatarGrayBg,
                  classes.largeAvatar
                )}
              >
                <WhatshotRoundedIcon />
              </IconButton>
              <IconButton
                className={classNames(
                  classes.avatarGrayBg,
                  classes.smallAvatar
                )}
              >
                <WhatshotRoundedIcon />
              </IconButton>
              <IconButton>
                <Box
                  className={classNames(
                    classes.mediumAvatar,
                    classes.avatarBorderWhite
                  )}
                  component="img"
                  src="/images/Frame_2.png"
                />
              </IconButton>
            </Stack>
            <Stack direction="row" flexWrap={"wrap"}>
              <Button
                className={classNames(
                  classes.smallBtn,
                  classes.bgGradientLight
                )}
                variant="contained"
              >
                Hide
              </Button>
              <Button className={classes.smallBtn}>
                <KeyboardArrowLeftOutlinedIcon />
                Nope
              </Button>
              <Button className={classes.smallBtn}>
                Like
                <KeyboardArrowRightOutlinedIcon />
              </Button>
              <Button className={classes.smallBtn}>
                <KeyboardArrowUpRoundedIcon />
                Open Profile
              </Button>
              <Button className={classes.smallBtn}>
                <KeyboardArrowDownRoundedIcon />
                Close Profile
              </Button>
              <Button className={classes.smallBtn}>
                <SubdirectoryArrowLeftOutlinedIcon />
                Super Like
              </Button>
              <Button className={classes.smallBtn}>
                <SpaceBarOutlinedIcon />
                Next Photo
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper elevation={0} square>
          <TabPanel />
        </Paper>
      </Grid>
    </Grid>
  );
};
Home.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(style)(Home);
