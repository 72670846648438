import { createTheme } from "@mui/material/styles";

// project imports
import componentStyleOverrides from "./compStyleOverride";
import themePalette from "./palette";
import themeTypography from "./typography";

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization) => {
  const colorPalette = themePalette(
    customization.themeName,
    customization.themeMode
  );

  const themeOptions = {
    direction: "ltr",
    palette: colorPalette,
    mixins: {
      toolbar: {
        minHeight: "48px",
        padding: "8px",
        "@media (min-width: 600px)": {
          minHeight: "48px",
        },
      },
    },
    typography: themeTypography({
      palette: colorPalette,
      customization: customization,
    }),
  };

  const themes = createTheme(themeOptions);

  themes.mode = customization.themeMode;
  themes.customization = customization;

  //themes.typography = themeTypography(themeOptions);
  themes.components = componentStyleOverrides(themes);

  return themes;
};

export default theme;
