// project imports
//import config from 'config';

// action - state management
import * as actionTypes from "./actions";
import config from "themes/config";

const themeName = localStorage.getItem("themeName");

export const initialState = {
  isOpen: [], // for active default menu
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  themeMode: config.themeMode,
  themeName: themeName || config.themeName,
  opened: true,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
  let id;
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      id = action.id;
      return {
        ...state,
        isOpen: [id],
      };
    case actionTypes.SET_MENU:
      return {
        ...state,
        opened: action.opened,
      };
    case actionTypes.SET_FONT_FAMILY:
      return {
        ...state,
        fontFamily: action.fontFamily,
      };
    case actionTypes.SET_BORDER_RADIUS:
      return {
        ...state,
        borderRadius: action.borderRadius,
      };
    case actionTypes.SET_THEME_MODE:
      return {
        ...state,
        themeMode: action.themeMode,
      };
    case actionTypes.SET_THEME_NAME:
      return {
        ...state,
        themeName: action.themeName,
      };
    default:
      return state;
  }
};

export default customizationReducer;
