/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

const darkPalatte = {
  darkBlue: {
    primary: {
      main: "#F9F6FF",
      light: "#F4F4FF",
      lighter: "#F0F0F0",
      gradient: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
      lightGradient:
        "linear-gradient(22.11deg, #35B9FF 22.54%, #7AD1FF 117.22%)",
      dark: "#0B5FDA",
      dark2: "#00103b",
    },
    secondary: {
      main: "#333333",
      light: "#262628",
      dark: "#181717",
      dark2: "#131313",
      default: "#2C2A2A",
      chat: "#282828",
    },
    white: {
      main: "#FFFFFF",
      gradient: "rgba(255, 255, 255, 0.2)",
    },
    gray: {
      main: "#A19FA8",
      light: "#8E8E8E",
      light2: "#AEAEAE",
      dark: "#90909B",
      dark2: "#C4C4C4",
      dark3: "#121422",
      dark4: "#6D6D6D",
      custom: "#E4E3E3",
      variant: "#9797BD",
      gradient1:
        "linear-gradient(109.46deg, rgba(201, 201, 201, 0.8) 1.57%, rgba(196, 196, 196, 0.1) 100%)",
      transparent: "#D9D9D9",
    },
    blue: {
      main: "#0A3474",
      dark: "linear-gradient(167.65deg, #121422 -12.82%, #02106D 144.95%)",
      gradient: "linear-gradient(167.83deg, #090839 -4.54%, #151755 113.49%)",
    },
    green: {
      main: "#27AE60",
    },
    black: {
      dark: "#131313",
    },
  },

  lightBlue: {
    primary: {
      main: "#F9F6FF",
      light: "#F4F4FF",
      lighter: "#F0F0F0",
      gradient: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
      lightGradient:
        "linear-gradient(22.11deg, #35B9FF 22.54%, #7AD1FF 117.22%)",
      dark: "#0B5FDA",
    },
    secondary: {
      main: "#333333",
      light: "#262628",
      dark: "#181717",
      dark2: "#131313",
      default: "#2C2A2A",
      chat: "#282828",
    },
    white: {
      main: "#FFFFFF",
      gradient: "rgba(255, 255, 255, 0.2)",
    },
    gray: {
      main: "#A19FA8",
      light: "#8E8E8E",
      light2: "#AEAEAE",
      dark: "#90909B",
      dark2: "#C4C4C4",
      dark3: "#121422",
      dark4: "#6D6D6D",
      custom: "#E4E3E3",
      variant: "#9797BD",
      gradient1:
        "linear-gradient(109.46deg, rgba(201, 201, 201, 0.8) 1.57%, rgba(196, 196, 196, 0.1) 100%)",
      transparent: "#D9D9D9",
    },
    blue: {
      main: "#0A3474",
      dark: "linear-gradient(167.65deg, #121422 -12.82%, #02106D 144.95%)",
      gradient: "linear-gradient(167.83deg, #090839 -4.54%, #151755 113.49%)",
    },
    green: {
      main: "#27AE60",
    },
    black: {
      dark: "#131313",
    },
  },
};

const lightPalatte = {
  darkBlue: {
    background: {
      default: "#FFFFFF",
    },
    primary: {
      main: "#00103b",
      light: "#FFFFFF",
      lighter: "#F0F0F0",
      gradient: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
      lightGradient:
        "linear-gradient(22.11deg, #35B9FF 22.54%, #7AD1FF 117.22%)",
      dark: "#0B5FDA",
      dark2: "#00103b",
    },
    secondary: {
      main: "#0B5FDA",
      light: "#F9F6FF",
      lighter: "#F3F3F3",
      gradient: "linear-gradient(167.65deg, #121422 -12.82%, #02106D 144.95%)",
    },

    white: {
      main: "#FFFFFF",
      gradient: "rgba(255, 255, 255, 0.2)",
    },
    gray: {
      main: "#E4E3E3",
      light: "#A19FA8",
    },
    blue: {
      main: "#0A3474",
      dark: "linear-gradient(167.65deg, #121422 -12.82%, #02106D 144.95%)",
      gradient: "linear-gradient(167.83deg, #090839 -4.54%, #151755 113.49%)",
    },
    green: {
      main: "#27AE60",
    },
    black: {
      dark: "#131313",
    },
    colors: {
      default: {
        main: "#F9F6FF",
        light: "#F4F4FF",
        lighter: "#F0F0F0",
      },
      black: {
        main: "#333333",
        light: "#262628",
        dark: "#181717",
        dark2: "#131313",
        default: "#2C2A2A",
        chat: "#282828",
      },
      white: {
        main: "#FFFFFF",
        gradient: "rgba(255, 255, 255, 0.2)",
      },
      primary: {
        gradient: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
        lightGradient:
          "linear-gradient(22.11deg, #35B9FF 22.54%, #7AD1FF 117.22%)",
        main: "#0B5FDA",
      },
      gray: {
        main: "#A19FA8",
        light: "#8E8E8E",
        light2: "#AEAEAE",
        dark: "#90909B",
        dark2: "#C4C4C4",
        dark3: "#121422",
        dark4: "#6D6D6D",
        custom: "#E4E3E3",
        variant: "#9797BD",
        gradient1:
          "linear-gradient(109.46deg, rgba(201, 201, 201, 0.8) 1.57%, rgba(196, 196, 196, 0.1) 100%)",
        transparent: "#D9D9D9",
      },
      blue: {
        main: "#0A3474",
        dark: "linear-gradient(167.65deg, #121422 -12.82%, #02106D 144.95%)",
        gradient: "linear-gradient(167.83deg, #090839 -4.54%, #151755 113.49%)",
      },
      green: {
        main: "#27AE60",
      },
    },
  },

  lightBlue: {
    background: {
      default: "#F9F6FF",
      paper: "#FFFFFF",
    },
    primary: {
      main: "#FFFFFF",
      light: "#F0F0F0",
      dark: "#0B5FDA",
      contrastText: "#131313",
      gradient: "rgba(255, 255, 255, 0.2)",
    },
    secondary: {
      main: "#35B9FF",
      light: "#7AD1FF",
      dark: "#0C59DB",
      800: "#03AACF",
      gradient: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
      lightGradient:
        "linear-gradient(22.11deg, #35B9FF 22.54%, #7AD1FF 117.22%)",
    },
    gray: {
      main: "#A19FA8",
      light: "#8E8E8E",
      light2: "#AEAEAE",
      dark: "#90909B",
      dark2: "#C4C4C4",
      dark3: "#121422",
      dark4: "#6D6D6D",
      custom: "#E4E3E3",
      variant: "#9797BD",
      gradient1:
        "linear-gradient(109.46deg, rgba(201, 201, 201, 0.8) 1.57%, rgba(196, 196, 196, 0.1) 100%)",
      transparent: "#D9D9D9",
    },
    blue: {
      main: "#0A3474",
      dark: "linear-gradient(167.65deg, #121422 -12.82%, #02106D 144.95%)",
      gradient: "linear-gradient(167.83deg, #090839 -4.54%, #151755 113.49%)",
    },
    green: {
      main: "#27AE60",
    },
    black: {
      dark: "#131313",
    },
  },
};

export default function themePalette(name, mode) {
  if (mode === "dark") {
    return darkPalatte[name];
  }
  return lightPalatte[name];
}
