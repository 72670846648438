import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
  return (
    <Box display={"flex"} alignItems="center">
      <Link to="/app/profile-edit">
        <Avatar alt="John Sharp" src="/images/Frame_2.png" />
      </Link>
      <Typography ml={1} variant="body1" fontWeight={500}>
        John Sharp
      </Typography>
    </Box>
  );
};

export default NotificationSection;
