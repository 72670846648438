export const GET_PARAMS = {
  popup: "popup",
  notificationId: "notification-id",
};

export const GET_ENUMS = {
  popup: {
    signIn: "sign-in",
    signUp: "sign-up",
    enterOtp: "otp",
    name: "name",
    gender: "gender",
    dob: "dob",
    ethnicity: "ethnicity",
    orientation: "orientation",
    photos: "photos",
    interest: "interest",
    finish: "finish",
    relationship: "relationship",
    about: "about",
    education: "education",
    orginization: "orginization",
    children: "children",
    drink: "drink",
  },
};
