/* global URLSearchParams */
import { useLocation } from "react-router-dom";

const GetParam = (name) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  return query.get(name);
};

export default GetParam;
