import * as React from "react";
import PropTypes from "prop-types";
import style from "themes/styles";
import { withStyles } from "@mui/styles";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Button,
  Stack,
} from "@mui/material";

import ProgressBar from "Components/ProgressBar";
import { GET_PARAMS, GET_ENUMS } from "PopupRoutes/components/settings";
import usePrepareLink from "hooks/usePrepareLink";
import { useNavigate } from "react-router-dom";
import CustomCheckBox from "Components/CustomCheckBox";

const Organization = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const nextPage = usePrepareLink({
    query: {
      [GET_PARAMS.popup]: GET_ENUMS.popup.children,
    },
  });
  const prvPage = usePrepareLink({
    query: {
      [GET_PARAMS.popup]: GET_ENUMS.popup.education,
    },
  });
  const HandleNext = () => {
    navigate(nextPage);
  };

  const HandleBack = () => {
    navigate(prvPage);
  };

  return (
    <>
      <DialogTitle>
        <ProgressBar value={55} />
      </DialogTitle>
      <DialogContent>
        <Box my={3} textAlign="center">
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => HandleNext()}
              variant="text"
              className={classes.skipBtn}
            >
              Skip
            </Button>
          </Box>
          <Typography
            color="secondary"
            variant="subtitle2"
            fontWeight="500"
            mb={1}
          >
            Lets setup your profile!
          </Typography>
          <Typography color="primary" variant="subtitle1" fontWeight="700">
            Organization Affiliation
          </Typography>
        </Box>
        <Stack spacing={"20px"} mx={3} mb={3}>
          <CustomCheckBox label="Eastern Star" />
          <CustomCheckBox label="ELK" />
          <CustomCheckBox label="Greek Life" />
          <CustomCheckBox label="Mason" />
          <CustomCheckBox label="NAACP" />
          <CustomCheckBox label="Urban League" />
          <CustomCheckBox label="NESBE, Military" />
          <CustomCheckBox label="None" />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack flex={1} justifyContent="space-between" direction="row">
          <Button onClick={() => HandleBack()} className={classes.backBtn}>
            Back
          </Button>
          <Button onClick={() => HandleNext()} className={classes.nextBtn}>
            Next
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};

Organization.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Organization);
