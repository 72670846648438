import * as React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import style from "themes/styles";
import { withStyles } from "@mui/styles";
import {
  DialogActions,
  DialogContent,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

import { GET_PARAMS, GET_ENUMS } from "PopupRoutes/components/settings";
import usePrepareLink from "hooks/usePrepareLink";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { SET_THEME_NAME } from "store/actions";

const Finish = (props) => {
  const navigate = useNavigate();
  const { classes } = props;
  const dispatch = useDispatch();
  const customization = useSelector((state) => state.customization);
  let themeName = customization.themeName;

  const handleFinish = () => {
    themeName = "lightBlue";
    localStorage.setItem("themeName", themeName);
    dispatch({ type: SET_THEME_NAME, themeName });
    navigate("/app");
  };

  const nextPage = usePrepareLink({
    query: {
      [GET_PARAMS.popup]: GET_ENUMS.popup.relationship,
    },
  });

  const HandleNext = () => {
    navigate(nextPage);
  };

  /* const HandleBack = () => {
    navigate(prvPage);
  }; */

  return (
    <Stack sx={{ padding: "38px 43px 43px 51px" }}>
      <DialogContent>
        <Stack spacing={3} alignItems="center" mt={6}>
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            className={classNames(classes.gradientBtn, classes.circleBox)}
          >
            <CheckRoundedIcon sx={{ fontSize: "60px" }} />
          </Stack>
          <Stack spacing={2}>
            <Typography variant="h2" color="secondary">
              You did it!
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          flex={1}
          alignItems="center"
          direction={"column"}
          spacing={1}
          mb={3}
        >
          <Button
            onClick={() => handleFinish()}
            className={classes.gradientBtn}
          >
            Finish
          </Button>
          <Button
            onClick={() => HandleNext()}
            className={classes.gradientText}
            fontWeight="500"
          >
            Add More details (Optional)
          </Button>
        </Stack>
      </DialogActions>
    </Stack>
  );
};

Finish.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(style)(Finish);
