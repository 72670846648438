import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { Divider, Stack, Typography } from "@mui/material";
import MessageCardRow from "./MessageCardRow";
import MessageCard from "./MessageCard";
import style from "themes/styles";
import { withStyles } from "@mui/styles";

const Tab = styled(TabUnstyled)`
  color: gray;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  width: 100%;
  padding: 13px 12px;
  border: none;
  border-radius: 14px;
  display: flex;
  justify-content: center;

  &.${tabUnstyledClasses.selected} {
    background: linear-gradient(22.11deg, #35b9ff 22.54%, #7ad1ff 117.22%);
    color: #fff;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.5rem;
`;

const TabsList = styled(TabsListUnstyled)(
  ({ theme }) => `
  width: 100%;
  background-color: #F4F4FF;
  border-radius: 14px;
  margin-bottom: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  `
);

function Tabpanel(props) {
  const { classes } = props;
  return (
    <Stack p={2}>
      <Stack
        sx={{
          marginBottom: "29px",
        }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="subtitle2"
          className={classes.gradientText}
          fontWeight="700"
        >
          Messages
        </Typography>
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          See all
        </Typography>
      </Stack>
      <TabsUnstyled defaultValue={0}>
        <TabsList>
          <Tab>Chats</Tab>
          <Tab>Matches</Tab>
        </TabsList>
        <TabPanel value={0}>
          <MessageCardRow
            name="Abhishek"
            msg="Thanks I really appreciate it"
            time="2 min ago"
            numberOfMsg="5"
            img="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg"
          />
          <Divider />
          <MessageCardRow
            name="Abhishek"
            msg="Thanks I really appreciate it"
            time="2 min ago"
            numberOfMsg="5"
            img="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg"
          />
          <Divider />
          <MessageCardRow
            name="Abhishek"
            msg="Thanks I really appreciate it"
            time="2 min ago"
            numberOfMsg="5"
            img="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg"
          />
          <Divider />
          <MessageCardRow
            name="Abhishek"
            msg="Thanks I really appreciate it "
            time="2 min ago"
            img="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg"
          />
          <Divider />
        </TabPanel>
        <TabPanel width={1} value={1}>
          <MessageCard />
        </TabPanel>
      </TabsUnstyled>
    </Stack>
  );
}

Tabpanel.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(style)(Tabpanel);
