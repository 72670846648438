import * as React from "react";
import PropTypes from "prop-types";
import style from "themes/styles";
import { withStyles } from "@mui/styles";
import {
  DialogContent,
  Link,
  TextField,
  Typography,
  Stack,
  Button,
  Box,
} from "@mui/material";

import { GET_PARAMS, GET_ENUMS } from "PopupRoutes/components/settings";
import usePrepareLink from "hooks/usePrepareLink";
import { useNavigate } from "react-router-dom";
import Logo from "Components/Logo";

const EnterOtp = (props) => {
  const { classes } = props;

  const navigate = useNavigate();
  const enterName = usePrepareLink({
    query: {
      [GET_PARAMS.popup]: GET_ENUMS.popup.name,
    },
  });

  const HandleContinue = () => {
    navigate(enterName);
  };

  return (
    <DialogContent>
      <Box textAlign="center">
        <Logo />
        <Stack spacing={3} sx={{ padding: "38px 20px 43px 20px" }}>
          <Typography variant="subtitle2" color="secondary" fontWeight="500">
            Enter OTP
          </Typography>
          <Typography variant="subtitle1" fontWeight="500">
            Verification mail sent to justin@g****
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <TextField
              style={{ width: "18%" }}
              fullWidth
              inputProps={{ maxLength: 1 }}
            />
            <TextField
              style={{ width: "18%" }}
              fullWidth
              inputProps={{ maxLength: 1 }}
            />
            <TextField
              style={{ width: "18%" }}
              fullWidth
              inputProps={{ maxLength: 1 }}
            />
            <TextField
              style={{ width: "18%" }}
              fullWidth
              inputProps={{ maxLength: 1 }}
            />
          </Stack>
          <Link component="button" underline="none">
            <Typography variant="subtitle1" fontWeight="500">
              Didn&apos;t got mail? we can resend it
            </Typography>
          </Link>
        </Stack>
      </Box>
      <Stack mb={2} mt={2}>
        <Button
          onClick={() => HandleContinue()}
          className={classes.gradientBtn}
        >
          Continue
        </Button>
      </Stack>
    </DialogContent>
  );
};
EnterOtp.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(style)(EnterOtp);
