import * as React from "react";
import PropTypes from "prop-types";
import style from "themes/styles";
import { withStyles } from "@mui/styles";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
  Box,
  Button,
  Stack,
} from "@mui/material";
import ProgressBar from "Components/ProgressBar";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { SET_THEME_NAME } from "store/actions";

const Drink = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFinish = () => {
    const themeName = "lightBlue";
    localStorage.setItem("themeName", themeName);
    dispatch({ type: SET_THEME_NAME, themeName });
    navigate("/app");
  };

  const [drink, setDrink] = React.useState("Yes");
  const [smoke, setSmoke] = React.useState("No");
  const [smokeItem, setSmokeItem] = React.useState("Other items");

  return (
    <>
      <DialogTitle>
        <ProgressBar value={100} />
      </DialogTitle>
      <DialogContent>
        <Box my={4}>
          <Typography
            variant="subtitle2"
            color="secondary"
            fontWeight="500"
            textAlign="center"
            mb={1}
          >
            Lets setup your profile!
          </Typography>
          <Typography
            color="primary"
            variant="subtitle1"
            fontWeight="700"
            textAlign="center"
          >
            Do you Drink?
          </Typography>
        </Box>
        <Stack justifyContent="center" direction="row" spacing={3} mb={5}>
          <Box textAlign="center">
            <Paper
              onClick={() => setDrink("Yes")}
              className={classNames(
                classes.circleBox,
                drink === "Yes" && classes.gradientBtn
              )}
              elevation={3}
            >
              <ThumbUpOffAltOutlinedIcon sx={{ fontSize: 55 }} />
            </Paper>
            <Typography color="secondary" variant="caption">
              Yes
            </Typography>
          </Box>
          <Box textAlign="center">
            <Paper
              onClick={() => setDrink("No")}
              className={classNames(
                classes.circleBox,
                drink === "No" && classes.gradientBtn
              )}
              elevation={3}
            >
              <ThumbDownOutlinedIcon sx={{ fontSize: 55 }} />
            </Paper>
            <Typography color="secondary" variant="caption">
              No
            </Typography>
          </Box>
        </Stack>
        <Box textAlign="center">
          <Typography color="primary" variant="subtitle1" fontWeight="700">
            Do you smoke?
          </Typography>
          <Stack
            justifyContent="center"
            direction="row"
            spacing={3}
            mt={2}
            mb={5}
          >
            <Box>
              <Paper
                onClick={() => setSmoke("Yes")}
                className={classNames(
                  classes.circleBox,
                  smoke === "Yes" && classes.gradientBtn
                )}
                elevation={3}
              >
                <ThumbUpOffAltOutlinedIcon sx={{ fontSize: 55 }} />
              </Paper>
              <Typography color="secondary" variant="caption">
                Yes
              </Typography>
            </Box>
            <Box>
              <Paper
                onClick={() => setSmoke("No")}
                className={classNames(
                  classes.circleBox,
                  smoke === "No" && classes.gradientBtn
                )}
                elevation={3}
              >
                <ThumbDownOutlinedIcon sx={{ fontSize: 55 }} />
              </Paper>
              <Typography color="secondary" variant="caption">
                No
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Box textAlign="center">
          <Typography color="primary" variant="subtitle1" fontWeight="700">
            Do you smoke?
          </Typography>
          <Stack
            justifyContent="center"
            direction="row"
            spacing={3}
            mt={2}
            mb={5}
          >
            <Box>
              <Paper
                onClick={() => setSmokeItem("Yes")}
                className={classNames(
                  classes.circleBox,
                  smokeItem === "Yes" && classes.gradientBtn
                )}
                elevation={3}
              >
                <ThumbUpOffAltOutlinedIcon sx={{ fontSize: 55 }} />
              </Paper>
              <Typography color="secondary" variant="caption">
                Cigerette
              </Typography>
            </Box>
            <Box>
              <Paper
                onClick={() => setSmokeItem("No")}
                className={classNames(
                  classes.circleBox,
                  smokeItem === "No" && classes.gradientBtn
                )}
                elevation={3}
              >
                <ThumbDownOutlinedIcon sx={{ fontSize: 55 }} />
              </Paper>
              <Typography color="secondary" variant="caption">
                Other Items
              </Typography>
            </Box>
            <Box>
              <Paper
                onClick={() => setSmokeItem("No")}
                className={classNames(
                  classes.circleBox,
                  smokeItem === "No" && classes.gradientBtn
                )}
                elevation={3}
              >
                <ThumbDownOutlinedIcon sx={{ fontSize: 55 }} />
              </Paper>
              <Typography color="secondary" variant="caption">
                Marijuana
              </Typography>
            </Box>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack flex={1} alignItems="center" direction={"column"} spacing={1}>
          <Button
            sx={{ maxWidth: "300px" }}
            onClick={() => handleFinish()}
            className={classes.gradientBtn}
          >
            Finish
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};

Drink.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Drink);
