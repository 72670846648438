import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Box, Stack } from "@mui/system";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import style from "themes/styles";
import { withStyles } from "@mui/styles";
import { IconButton, Typography } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import useWindowDimensions from "utils/useWindowDimensions";

function FeedImages(props) {
  const { classes } = props;
  const { width } = useWindowDimensions();

  return (
    <ImageList
      sx={{
        gap: "16px !important",
        overflow: "inherit !important",
        marginBottom: "10px",
      }}
      cols={width < 599 ? 3 : 4}
    >
      {itemData.map((item) => (
        <Box sx={{ position: "relative" }}>
          <ImageListItem key={item.img} sx={{ borderRadius: "5px" }}>
            <img
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
          <Stack direction="row" sx={{ position: "absolute", bottom: "0px" }}>
            <IconButton aria-label="delete" size="small">
              <FavoriteBorderIcon className={classes.sideBarButton} />
              <Typography
                color="primary.light"
                sx={{ marginLeft: "3px" }}
                variant="body1"
              >
                2.3k
              </Typography>
            </IconButton>
            <IconButton aria-label="delete" size="small">
              <RemoveRedEyeOutlinedIcon className={classes.sideBarButton} />
              <Typography
                color="primary.light"
                sx={{ marginLeft: "3px" }}
                variant="body1"
              >
                5k
              </Typography>
            </IconButton>
          </Stack>
        </Box>
      ))}
    </ImageList>
  );
}
export default withStyles(style)(FeedImages);

const itemData = [
  {
    img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    title: "Breakfast",
  },
  {
    img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    title: "Burger",
  },
  {
    img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
    title: "Camera",
  },
  {
    img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
    title: "Coffee",
  },
];
