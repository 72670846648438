import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { Outlet } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { AppBar, Box, Toolbar, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SET_MENU } from "store/actions";
import Header from "./Header";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Filter from "pages/App/Filter";
import { withStyles } from "@mui/styles";
import style from "themes/styles";

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    ...theme.typography.mainContent,
  })
);

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 1 : 0,
  });
}

// ==============================|| MAIN LAYOUT ||============================== //
const MainLayout = (props) => {
  const { classes } = props;
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();

  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  useEffect(() => {
    dispatch({ type: SET_MENU, opened: !matchDownMd });
  }, [matchDownMd]);

  return (
    <Box sx={{ display: "flex" }}>
      <ElevationScroll {...props}>
        <AppBar
          enableColorOnDark
          position="fixed"
          sx={{
            transition: leftDrawerOpened
              ? theme.transitions.create("width")
              : "none",
          }}
        >
          <Toolbar disableGutters>
            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Box className={classes.mainContainer}>
        <Outlet />
        <Filter />
      </Box>
    </Box>
  );
};

MainLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(MainLayout);
