import React, { useState } from "react";
import PropTypes from "prop-types";
import style from "themes/styles";
import { withStyles } from "@mui/styles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import {
  DialogContent,
  Divider,
  Link,
  TextField,
  Typography,
  Box,
  Button,
  Fab,
  Stack,
} from "@mui/material";
import Logo from "Components/Logo";
import { GET_PARAMS, GET_ENUMS } from "PopupRoutes/components/settings";
import usePrepareLink from "hooks/usePrepareLink";
import { useNavigate } from "react-router-dom";
import { fbIcon, googleIcon } from "assets/images";

const Login = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const enterOtp = usePrepareLink({
    query: {
      [GET_PARAMS.popup]: GET_ENUMS.popup.enterOtp,
    },
  });

  const HandleContinue = () => {
    navigate(enterOtp, { state: { title: false, close: true } });
  };

  const [loginMode, setLoginMode] = useState("Email");
  const handleInstead = (mode) => {
    setLoginMode(mode);
  };

  const [phone, setPhone] = React.useState("");

  return (
    <DialogContent>
      <Box flex={1} textAlign={"center"}>
        <Logo />
        <Box sx={{ padding: "38px 20px 43px 20px" }}>
          <Stack spacing={5}>
            <Stack direction="row" justifyContent="center" spacing={3}>
              <Fab className={classes.socialIcon} aria-label="add">
                <Box
                  component={"img"}
                  src={fbIcon}
                  alt="logo"
                  sx={{ width: "20px" }}
                />
              </Fab>
              <Fab className={classes.socialIcon} aria-label="add">
                <Box
                  component={"img"}
                  src={googleIcon}
                  alt="logo"
                  sx={{ width: "20px" }}
                />
              </Fab>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Divider className={classes.halfDivider} />
              <Typography variant="body2" color="primary">
                Or Login with
              </Typography>
              <Divider className={classes.halfDivider} />
            </Stack>

            {loginMode === "Email" ? (
              <Stack spacing={2}>
                <TextField fullWidth placeholder="Username or Email" />
                <Link
                  component="button"
                  fontWeight="500"
                  onClick={() => handleInstead("Number")}
                  color="primary"
                  variant="subtitle1"
                  underline="none"
                >
                  Use phone number instead
                </Link>
              </Stack>
            ) : (
              <Stack spacing={2}>
                <PhoneInput
                  enableSearch
                  specialLabel=""
                  country={"in"}
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                />
                <Link
                  component="button"
                  fontWeight="500"
                  onClick={() => handleInstead("Email")}
                  color="primary"
                  variant="subtitle1"
                  underline="none"
                >
                  Use Email instead
                </Link>
              </Stack>
            )}

            <Stack spacing={2}>
              <Button
                onClick={() => HandleContinue()}
                className={classes.gradientBtn}
              >
                Continue
              </Button>
              <Typography variant="subtitle1">
                <Link
                  component="button"
                  onClick={() => handleInstead("Email")}
                  color="secondary"
                  variant="subtitle1"
                  underline="none"
                >
                  Signup&nbsp;
                </Link>
                <Typography variant="subtitle1" component={"span"}>
                  if you are new Blerdy
                </Typography>
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </DialogContent>
  );
};

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Login);
