import * as React from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, FormControl, TextField, Stack } from "@mui/material";
import { withStyles } from "@mui/styles";
import style from "themes/styles";
import SuccessModal from "./SuccessModal";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#F9F6FF",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};
function AddCardModal(props) {
  const [successOpen, setSuccessOpen] = React.useState(false);
  const { modalopen, closeModal, classes } = props;

  const handlePay = () => {
    setSuccessOpen(true);
  };

  return (
    <div>
      <Modal
        open={modalopen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography variant="subtitle1" fontWeight="600" mb={3}>
            Add card
          </Typography>
          <Typography variant="body2" fontWeight={500} mb={2}>
            Fill card details
          </Typography>
          <Stack direction={"column"} spacing={2}>
            <Box>
              <Typography variant="body1" fontWeight={500} mb={1}>
                Card Number
              </Typography>
              <FormControl variant="outlined" fullWidth>
                <TextField
                  fullWidth
                  className={classes.textField}
                  placeholder="Enter Card Number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          style={{
                            height: 20,
                            width: 40,
                            marginRight: "10px",
                          }}
                          alt="The house from the offer."
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Visa_Inc._logo.svg/2560px-Visa_Inc._logo.svg.png"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Box>
            <Stack direction={"row"} spacing={2}>
              <TextField className={classes.textField} placeholder="MM / YY " />
              <TextField
                className={classes.textField}
                placeholder="Enter CVV"
              />
            </Stack>
            <Box>
              <Typography variant="body1" fontWeight={500} mb={1}>
                Cardholder name
              </Typography>
              <TextField
                className={classes.textField}
                fullWidth
                placeholder="Cardholder Name"
              />
            </Box>
            <Button
              onClick={() => handlePay()}
              sx={{ fontSize: "14px !important", maxHeight: 50 }}
              className={classes.gradientBtn}
            >
              Add Card
            </Button>
          </Stack>
          <SuccessModal
            modalOpen={successOpen}
            closeModal={() => setSuccessOpen(false)}
          />
        </Box>
      </Modal>
    </div>
  );
}
export default withStyles(style)(AddCardModal);
