import { Box } from "@mui/system";
import React from "react";
import { withStyles } from "@mui/styles";
import style from "themes/styles";
import {
  Avatar,
  IconButton,
  InputBase,
  Stack,
  Grid,
  Typography,
  Paper,
  Divider,
  Menu,
  Fab,
  MenuItem,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import BlackModal from "./BlockModal";
import TabPanel from "Components/RightBar/TabPanel";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";

const ChatBox = (props) => {
  const { classes } = props;
  const [openModal, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const closeModal = () => {
    setOpen(false);
  };
  const modalOpen = () => {
    setOpen(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={9}>
        <Paper display="flex" flexDirection="column" elevation={0}>
          <Stack
            p={1}
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack direction="row" alignItems={"center"}>
              <IconButton aria-label="back" size="small">
                <ArrowBackIosIcon />
              </IconButton>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Avatar
                  className={classes.chatBoxAvatar}
                  alt="Remy Sharp"
                  src="https://mui.com/static/images/avatar/1.jpg"
                />
                <Typography
                  variant="subtitle1"
                  className={classes.gradientText}
                  fontWeight="500"
                >
                  Cooper Baptista
                </Typography>
              </Stack>
            </Stack>
            <IconButton
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              size="small"
            >
              <MoreVertIcon color="primary.dark2" />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}>Report</MenuItem>
              <MenuItem onClick={() => modalOpen()}>Delete</MenuItem>
              <MenuItem onClick={handleClose}>Unmatch</MenuItem>
            </Menu>
          </Stack>
          <Divider />
          <Box flex={1}>
            <Stack
              p={2}
              direction="column"
              justifyContent={"space-between"}
              spacing={1}
            >
              <Box>
                <Stack mb={1} direction="row" spacing={2} alignItems="end">
                  <Avatar
                    className={classes.chatBoxAvatar}
                    alt="Remy Sharp"
                    src="https://mui.com/static/images/avatar/1.jpg"
                  />
                  <Box>
                    <Box className={classes.receivedMsg}>
                      <Typography variant="body2" color="primary.dark2">
                        Lorem ipsum dolor
                      </Typography>
                    </Box>
                    <Typography variant="body1" color="primary.dark2">
                      12:00 PM
                    </Typography>
                  </Box>
                </Stack>
                <Stack mb={1} direction="column" justifyContent={"end"}>
                  <Stack direction="row" mb={1} justifyContent={"end"}>
                    <Box className={classes.sendMsg}>
                      <Typography variant="body2" color="primary.dark2">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dignissimos, deleniti.
                      </Typography>
                      <Box sx={{ textAlign: "end" }}>
                        <DoneAllRoundedIcon />
                      </Box>
                    </Box>
                  </Stack>
                  <Box textAlign={"end"}>
                    <Typography variant="body1" color="primary.dark2">
                      12:00 PM
                    </Typography>
                  </Box>
                </Stack>
                <Stack mb={1} direction="row" spacing={2}>
                  <Avatar
                    className={classes.chatBoxAvatar}
                    alt="Remy Sharp"
                    src="https://mui.com/static/images/avatar/1.jpg"
                  />
                  <Stack direction="column">
                    <Box className={classes.receivedMsg}>
                      <Typography variant="body2" color="primary.dark2">
                        Next month?
                      </Typography>
                    </Box>
                    <Stack
                      direction="row"
                      alignItems={"center"}
                      className={classes.receivedMsg}
                    >
                      <PlayArrowRoundedIcon
                        color="primary.dark2"
                        sx={{
                          fontSize: "40px",
                        }}
                      />
                      <Stack>
                        <Box
                          mb={1}
                          sx={{
                            width: "100%",
                            maxHeight: "25px",
                          }}
                          component={"img"}
                          src="/images/waves.png"
                        />
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography variant="body1" color="primary.dark2">
                            0:26
                          </Typography>
                          <Typography variant="body1" color="primary.dark2">
                            7:24Pm
                          </Typography>
                        </Stack>
                      </Stack>
                      <Avatar
                        className={classes.chatBoxAudioAvatar}
                        alt="Remy Sharp"
                        src="https://mui.com/static/images/avatar/1.jpg"
                      />
                    </Stack>
                  </Stack>
                </Stack>
                <Stack direction="row">
                  <Stack className={classes.chatInputBox} direction="row">
                    <IconButton aria-label="delete">
                      <SentimentSatisfiedAltIcon color="primary.dark2" />
                    </IconButton>
                    <Box flex={1}>
                      <InputBase
                        fullWidth
                        placeholder={"Enter your message here..."}
                      />
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "auto",
                      }}
                    >
                      <Fab className={classes.sendBtn} aria-label="add">
                        <SendRoundedIcon variant="subtitle1" />
                      </Fab>
                    </Box>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          </Box>

          <BlackModal openModal={openModal} closeModal={() => closeModal()} />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper elevation={0} square sx={{ height: "100%" }}>
          <TabPanel />
        </Paper>
      </Grid>
    </Grid>
  );
};
export default withStyles(style)(ChatBox);

const actions = [
  { icon: <FileCopyIcon />, name: "Copy" },
  { icon: <SaveIcon />, name: "Save" },
  { icon: <PrintIcon />, name: "Print" },
  { icon: <ShareIcon />, name: "Share" },
];
