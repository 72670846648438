import { Box } from "@mui/material";
import React from "react";
import { largeLogo } from "assets/images";

function Logo() {
  return (
    <Box
      component={"img"}
      src={largeLogo}
      alt="logo"
      sx={{
        width:'98px',
        height: 38,
        maxWidth: "133px",
        maxHeight: "55px", 
      }}
    />
  );
}

export default Logo;
