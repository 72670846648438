import React from "react";
import PropTypes from "prop-types";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { withStyles } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import style from "themes/styles";

const BpIcon = styled("span")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 16,
  height: 16,
  borderRadius: 6,
  border: "2px solid #C4C4C4",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    background: "none",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  width: 16,
  height: 16,
  border: "none",
  background:
    "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%) !important",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
});

function BpCheckbox() {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
    />
  );
}

const CustomCheckBox = (props) => {
  const { classes, label } = props;
  return (
    <FormGroup className={classes.selectBox}>
      <FormControlLabel
        sx={{ justifyContent: "space-between", margin: "0" }}
        control={<BpCheckbox className={classes.checkBox} defaultChecked />}
        label={label}
        labelPlacement="start"
      />
    </FormGroup>
  );
};

CustomCheckBox.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.any,
};

export default withStyles(style)(CustomCheckBox);
