import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import style from "themes/styles";
import { withStyles } from "@mui/styles";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material/styles";

import MobileStepper from "@mui/material/MobileStepper";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    id: 1,
    label: "Cooper Baptista",
    imgPath:
      "https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60",
  },
  {
    id: 2,
    label: "Jake Pratt",
    imgPath:
      "https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60",
  },
  {
    id: 3,
    label: "Jessica Smith",
    imgPath:
      "https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250",
  },
  {
    id: 4,
    label: "Anna Durand",
    imgPath:
      "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
  },
];

const ProfilePreview = (props) => {
  const { classes } = props;

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const renderStepper = () => {
    return (
      <Box className={classes.sliderDots}>
        {images.map((step, index) => (
          <Chip
            disabled={index !== activeStep}
            key={"stepper-btn-" + index}
          ></Chip>
        ))}
      </Box>
    );
  };

  return (
    <Box>
      <Box className={classes.profileContainer}>
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {images.map((step, index) => {
            return Math.abs(activeStep - index) <= 2 ? (
              <Box
                key={step.label}
                component="img"
                className={classes.feedImg}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null;
          })}
        </AutoPlaySwipeableViews>

        <Box className={classes.feedImgDetails}>
          <Typography variant="subtitle2" color="primary">
            {images[activeStep].label}
          </Typography>
          <Typography
            variant="body2"
            s
            component="p"
            color="primary"
            fontWeight={400}
            mb={1}
          >
            @Lindsey
          </Typography>
          <Typography
            variant="body1"
            component="p"
            color="primary"
            fontWeight={400}
            mb={3}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo.
          </Typography>
        </Box>

        {renderStepper()}

        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          variant=""
          classes={{
            root: classes.sliderRoot,
            dotActive: classes.sliderDotActive,
          }}
          nextButton={
            <Button
              size="large"
              color="gray"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="large"
              color="gray"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
            </Button>
          }
        />
      </Box>

      <Box
        width={1}
        px={2}
        sx={{
          margin: "0 auto",
          width: "90%",
        }}
      >
        <Typography variant="subtitle1" fontWeight={600} color="primary.dark2">
          Allow me to introduce myself.
        </Typography>
        <Typography variant="body2" color="gray.main" fontWeight={400} mb={3}>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also
        </Typography>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Card sx={{ boxShadow: 0 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="330"
                    image="https://images.freeimages.com/images/large-previews/f5b/a-child-1431845.jpg"
                    alt="green iguana"
                    sx={{
                      borderRadius: "10px",
                    }}
                  />
                  <CardContent>
                    <Stack spacing={2}>
                      <Box>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems={"center"}
                          mb={"6px"}
                        >
                          <Box
                            component="img"
                            className={classes.uploadImg}
                            alt="The house from the offer."
                            src="/images/profilePage_icon/ethnicity.png"
                          />
                          <Typography
                            gutterBottom
                            color="primary.dark2"
                            variant="body1"
                          >
                            My Ethnicity is:
                          </Typography>
                        </Stack>
                        <Typography sx={{ ml: 4 }} variant="p" color="gray">
                          Hetrosexual
                        </Typography>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems={"center"}
                          mb={"6px"}
                        >
                          <Box
                            component="img"
                            className={classes.uploadImg}
                            alt="The house from the offer."
                            src="/images/profilePage_icon/relationship.png"
                          />
                          <Typography
                            gutterBottom
                            color="primary.dark2"
                            variant="body1"
                          >
                            My sexual orientation is:
                          </Typography>
                        </Stack>
                        <Typography sx={{ ml: 4 }} variant="p" color="gray">
                          Hetrosexual
                        </Typography>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems={"center"}
                          mb={"6px"}
                        >
                          <Box
                            component="img"
                            className={classes.uploadImg}
                            alt="The house from the offer."
                            src="/images/profilePage_icon/ethnicity.png"
                          />
                          <Typography
                            gutterBottom
                            color="primary.dark2"
                            variant="body1"
                          >
                            Relationship Goals
                          </Typography>
                        </Stack>
                        <Typography sx={{ ml: 4 }} variant="p" color="gray">
                          Engage
                        </Typography>
                      </Box>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card sx={{ boxShadow: 0 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="330"
                    image="https://images.freeimages.com/variants/hGje8oXQPYzKXjx8kaYiRmaN/5a0bd68e593adb18739fe6fe526aa40368c6d1e5924c85e47ba06f7a665f1000"
                    alt="green iguana"
                    sx={{
                      borderRadius: "10px",
                    }}
                  />
                  <CardContent>
                    <Stack spacing={2}>
                      <Box>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems={"center"}
                          mb={"6px"}
                        >
                          <Box
                            component="img"
                            className={classes.uploadImg}
                            alt="The house from the offer."
                            src="/images/profilePage_icon/ethnicity.png"
                          />
                          <Typography
                            gutterBottom
                            color="primary.dark2"
                            variant="body1"
                          >
                            Level of Education
                          </Typography>
                        </Stack>
                        <Typography sx={{ ml: 4 }} variant="p" color="gray">
                          PHD
                        </Typography>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems={"center"}
                          mb={"6px"}
                        >
                          <Box
                            component="img"
                            className={classes.uploadImg}
                            alt="The house from the offer."
                            src="/images/profilePage_icon/ethnicity.png"
                          />
                          <Typography
                            gutterBottom
                            color="primary.dark2"
                            variant="body1"
                          >
                            Organization Affiliation
                          </Typography>
                        </Stack>
                        <Typography sx={{ ml: 4 }} variant="p" color="gray">
                          ELK
                        </Typography>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems={"center"}
                          mb={"6px"}
                        >
                          <Box
                            component="img"
                            className={classes.uploadImg}
                            alt="The house from the offer."
                            src="/images/profilePage_icon/ethnicity.png"
                          />
                          <Typography
                            gutterBottom
                            color="primary.dark2"
                            variant="body1"
                          >
                            Height
                          </Typography>
                        </Stack>
                        <Typography sx={{ ml: 4 }} variant="p" color="gray">
                          Engage
                        </Typography>
                      </Box>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Button
          href="/app/profile-edit"
          sx={{ maxWidth: "20%", maxHeight: 50, mt: 2, mb: 2 }}
          className={classes.gradientBtn}
        >
          Back
        </Button>
      </Box>
    </Box>
  );
};
export default withStyles(style)(ProfilePreview);
