const style = (theme) => {
  return {
    LoginBtn: {
      textTransform: "capitalize",
      width: "140px",
      fontWeight: "bold",
      backgroundColor: theme.palette.primary.light,
      marginRight: "40px",
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
    },
    gradientText: {
      background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    customButtonSize: {
      height: "60px",
      width: "306px",
      fontSize: "18px",
      marginTop: "20px",
      background: theme.palette.primary.gradient,
      color: theme.palette.primary.light,
      textTransform: "capitalize",
      borderRadius: "10px",
      fontWeight: 700,
    },
    gradientBtn: {
      background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
      color: theme.palette.primary.light,
      textTransform: "capitalize",
      borderRadius: "10px",
      height: "60px",
      width: "100%",
      fontSize: "18px",
      fontWeight: 700,
    },
    socialIcon: {
      background: theme.palette.primary.light,
      maxWidth: "50px",
      maxHeight: "50px",
    },
    halfDivider: {
      borderBottom: "1.16871px dashed #059FD1",
      opacity: "0.2",
      width: "30%",
    },
    dialog: {
      "& .MuiDialog-paper": {
        borderRadius: theme.spacing(2.5),
        [theme.breakpoints.down("sm")]: {
          borderRadius: "0px",
        },
        background: theme.palette.secondary.light,
        "&::before": {
          content: '" "',
          position: "absolute",
          backgroundSize: "200px, 280px",
          backgroundImage: "url(/images/bg-1.png), url(/images/bg-2.png)",
          backgroundRepeat: "no-repeat, no-repeat",
          backgroundPosition: "-57px -56px, 171% 106%",
          width: "100%",
          height: "100%",
          zIndex: "-2",
        },
      },
      "& .MuiDialogActions-root": {
        padding: theme.spacing(2) + " 0",
      },
    },
    backBtn: {
      textTransform: "capitalize",
      color: theme.palette.primary.light,
      background: theme.palette.secondary.gradient,
      borderRadius: "0px 10px 10px 0px",
      padding: "18px 42px",
    },
    nextBtn: {
      textTransform: "capitalize",
      color: theme.palette.primary.light,
      background: theme.palette.primary.gradient,
      borderRadius: "10px 0px 0px 10px",
      padding: "18px 42px",
    },
    circleBox: {
      height: 120,
      width: 120,
      [theme.breakpoints.down("sm")]: {
        height: 100,
        width: 100,
      },
      "& img": {
        height: "auto",
        width: "100%",
      },
      borderRadius: "50%",
      marginBottom: "10px",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:hover": {
        background: theme.palette.primary.gradient,
        color: theme.palette.primary.light,
      },
    },
    selectBox: {
      background: "#FFFFFF",
      border: "1px solid #E4E3E3",
      borderRadius: "10px",
      padding: "8px 15px",
      "& .MuiTypography-root": {
        color: theme.palette.primary.main,
        fontSize: 14,
      },
    },
    uploadIcon: {
      color: "rgba(11, 95, 218, 1)",
      fontSize: "28px",
    },
    uploadBorder: {
      background: theme.palette.secondary.light,
      border: `1px dashed ${theme.palette.secondary.main}`,
      height: "120px",
      width: "100%",
      borderRadius: "5px",
    },
    removeBtn: {
      background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
      color: theme.palette.primary.light,
      position: "absolute",
      right: "-10px",
      top: "-10px",
      zIndex: "15",
      border: `1px solid ${theme.palette.secondary.lighter}`,
      height: "20px",
      width: "20px",
    },
    commentBox: {
      border: `2px solid ${theme.palette.gray.main}`,
      borderRadius: "10px",
      padding: "15px",
      "&.Mui-focused": {
        border: `2px solid ${theme.palette.secondary.main}`,
      },
    },
    skipBtn: {
      color: theme.palette.darkBlue + "!important",
      textTransform: "capitalize !important",
      padding: "0 !important",
    },

    // Dashboard CSS
    mainContainer: {
      flex: 1,
      marginTop: theme.spacing(8),
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(7),
      },
    },
    contentBox: {
      width: "100%",
      padding: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        //margin: theme.spacing(0),
      },
    },
    roundedImg: {
      borderRadius: theme.spacing(2),
      width: "100%",
    },
    avatarGrayBg: {
      background:
        "linear-gradient(4deg, rgb(163 161 161) 1.57%, rgb(196 196 196 / 52%) 100%), linear-gradient(0deg, #ffffff, #FFFFFF)",
      color: theme.palette.primary.main,
      border: `2.93px solid ${theme.palette.primary.main}`,
      cursor: "pointer",
    },
    avatarBorderWhite: {
      border: `3px solid ${theme.palette.primary.main}`,
      borderRadius: "50% !important",
      cursor: "pointer",
    },
    smallAvatar: {
      height: 64,
      width: 64,
      [theme.breakpoints.down("sm")]: {
        height: 35,
        width: 35,
      },
    },
    largeAvatar: {
      height: 90,
      width: 90,
      [theme.breakpoints.down("sm")]: {
        height: 60,
        width: 60,
      },
    },
    mediumAvatar: {
      height: 68,
      width: 68,
      [theme.breakpoints.down("sm")]: {
        height: 40,
        width: 40,
      },
    },
    bgGradientLight: {
      background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
    },
    smallBtn: {
      padding: "5px 10px",
      marginTop: "8px !important",
      marginRight: "8px !important",
      fontSize: 10,
      border: "none",
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      textTransform: "capitalize",
      "& .MuiSvgIcon-root": {
        color: "#0B5FDA",
        fontSize: 14,
      },
      "&:hover": {
        background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
        color: theme.palette.primary.light,
      },
      "&:hover .MuiSvgIcon-root": {
        color: theme.palette.primary.light,
      },
    },
    toggleButton: {
      border: "none",
      maxWidth: "150px",
      width: "100%",
      height: "4px",
      padding: "0px",
      backgroundColor: "#D9D9D9",
      "&.Mui-selected, &.Mui-selected:hover": {
        color: theme.palette.primary.main,
        background:
          "linear-gradient(22.11deg, #35B9FF 22.54%, #7AD1FF 117.22%)",
      },
    },

    //Profile
    btn: {
      backgroundColor: "rgb(255,255,255,0.2)",
      color: theme.palette.primary.dark2,
      fontSize: 12,
    },
    profileShow: {
      position: "relative",
      width: "375px",
      height: "744px",
      borderRadius: "5px",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "0px",
      },
      overflow: "hidden",
      margin: "0 auto",
      backgroundImage:
        "url('https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&w=350&dpr=2')",
      maxWidth: { xs: 350, md: "100%" },
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      display: "flex",
      alignItems: "center",
    },
    profileShowGradient: {
      background:
        "linear-gradient(167.65deg, #121422 -12.82%, #02106D 144.95%)",
      position: "absolute",
      bottom: "0",
      width: "100%",
      maxWidth: "354px",
      borderRadius: "20px 20px 0px 0px",
    },
    smallIconBg: {
      height: "50px",
      width: "50px",
      background:
        "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%),linear-gradient(108.87deg, rgba(255, 255, 255, 0.8) 0.66%, rgba(211, 211, 211, 0.1) 99.48%)",
    },
    bigIconBg: {
      height: "66px",
      width: "66px",
      background:
        "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%),linear-gradient(108.87deg, rgba(255, 255, 255, 0.8) 0.66%, rgba(211, 211, 211, 0.1) 99.48%)",
      alignSelf: "flex-start",
    },

    //Chat
    chatBoxHeight: {
      height: "calc(100vh - 80px)",
      position: "relative",
    },
    chatBoxAvatar: {
      width: "50px",
      height: "50px",
    },
    chatBoxAudioAvatar: {
      width: "45px",
      height: "45px",
    },
    sendMsg: {
      background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
      padding: "12px 14px",
      borderRadius: "14px 14px 0px 14px",
      mb: "3px",
      color: theme.palette.primary.main,
    },
    receivedMsg: {
      background: "#F9F6FF",
      padding: "12px 14px",
      borderRadius: "14px 14px 14px 0px",
      marginBottom: "12px",
    },
    chatInputBox: {
      boxShadow: "0px 0px 20px 0px #00000012",
      padding: "8px 6px",
      borderRadius: "74px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      background: theme.palette.primary.main,
    },
    sendBtn: {
      background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
      float: "right",
      height: "43px",
      width: "44px",
      color: theme.palette.primary.main,
    },

    // edit profile
    tab: {
      "& .MuiBox-root": {
        padding: "0px",
      },
    },
    profilePrwBnt: {
      fontSize: 16,
      background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      border: "1px solid #03AACF !important",
      height: "48px",
      maxWidth: "307px",
      width: "100%",
      borderRadius: "10px !important",
    },
    textField: {
      "& .MuiInputBase-root": {
        borderRadius: theme.spacing(1.25),
        border: `2px solid ${theme.palette.gray.main}`,
        background: theme.palette.primary.main,
        height: "55px",
        boxSizing: "border-box",
      },
    },
    sideBarButton: {
      color: theme.palette.primary.dark,
      fontSize: 18,
    },
    uploadImg: {
      height: 24,
      width: 24,
      maxHeight: { xs: 233, md: 167 },
      maxWidth: { xs: 350, md: 250 },
    },

    //Setting
    activeBg: {
      backgroundColor: "#35B9FF",
      borderRadius: "10px",
      color: theme.palette.primary.main,
      padding: "10px",
      height: 50,
      marginBottom: 20,
      justifyContent: "space-between",
    },
    inactiveBg: {
      color: theme.palette.primary.dark,
      marginBottom: 20,
      justifyContent: "space-between",
    },
    linkStyle: {
      textDecoration: "none",
    },
    dividerColor: {
      borderColor: "rgba(53, 185, 255, 1)" + "!important",
      opacity: "1",
    },
    dashedDivider: {
      border: "1px dashed #FFFFFF" + "!important",
      opacity: "0.2",
      margin: "5px 0px !important",
    },
    cardHeaderBtn: {
      background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
      color: "white",
      textTransform: "capitalize",
      maxWidth: "128px",
      borderRadius: "50px",
      height: "50px",
      width: "100px",
      fontWeight: "400",
      fontSize: "16px",
      marginTop: "-25px",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
    },
    planCard: {
      background:
        "linear-gradient(92.46deg, rgba(12, 89, 219, 0.2) -3.95%, rgba(3, 170, 207, 0.2) 97.96%), linear-gradient(167.83deg, #090839 -4.54%, #151755 113.49%)",
      borderRadius: "10px",
      marginTop: "60px",
    },

    //Feed
    feedContainer: {
      borderRadius: theme.spacing(1),
      width: "100%",
      height: "100%",
      flexGrow: 1,
      position: "relative",
      marginBottom: theme.spacing(2),
      '& div:first-of-type[style="overflow-x: hidden;"], div:first-of-type[style="overflow-x: hidden;"] .react-swipeable-view-container':
        {
          height: "100%",
        },
    },
    profileContainer: {
      width: "100%",
      height: "450px",
      flexGrow: 1,
      position: "relative",
      marginBottom: theme.spacing(2),
      '& div:first-of-type[style="overflow-x: hidden;"], div:first-of-type[style="overflow-x: hidden;"] .react-swipeable-view-container':
        {
          height: "100%",
          "& img": {
            borderRadius: theme.spacing(0),
          },
        },
    },
    feedImg: {
      height: "100%",
      width: "100%",
      display: "block",
      overflow: "hidden",
      borderRadius: theme.spacing(1),
      objectFit: "cover",
    },
    feedImgDetails: {
      width: "50%",
      flexGrow: 1,
      bottom: 20,
      left: 20,
      zIndex: 1,
      position: "absolute",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
        bottom: 10,
        left: 10,
      },
    },
    transIcon: {
      height: "50px",
      width: "50px",
      backgroundColor: "rgb(255,255,255,0.2)",
      color: "white",
      border: "2px solid #ffffff73",
      [theme.breakpoints.down("sm")]: {
        height: 35,
        width: 35,
      },
    },
    sliderRoot: {
      backgroundColor: "transparent",
      width: "100%",
      position: "absolute",
      zIndex: 10,
      top: "35%",
    },
    sliderDots: {
      backgroundColor: "transparent",
      borderRadius: "5px",
      position: "absolute",
      top: 20,
      zIndex: 1,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      gap: 5,
      "& .MuiChip-root": {
        height: 5,
        width: "20%",
        backgroundColor: theme.palette.secondary.main,
      },
      "& .MuiChip-root.Mui-disabled": {
        height: 5,
        width: "20%",
        backgroundColor: theme.palette.gray.transparent,
      },
    },
    sliderDotActive: {
      backgroundColor: "#35B9FF",
    },
  };
};

export default style;
