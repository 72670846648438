import * as React from "react";
import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/system";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import Premium from "./Premium";
import Boosters from "./Boosters";
import MenuIcon from "@mui/icons-material/Menu";
import SettingSidebar from "./SettingSidebar";
import MyPlan from "./MyPlan";

const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
  maxWidth: "156px",
  color: "#0A3474",
  textTransform: "capitalize",
  border: "none",
  height: "36px",
  borderRadius: "50px !important",
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    background:
      "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%) !important",
  },
}));
export default function UpgradePlan() {
  const [alignment, setAlignment] = React.useState("left");
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const activePlan = localStorage.getItem("plan");

  const handleDrawerToggle = (props) => {
    setMobileOpen(!mobileOpen);
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <Box>
      <Grid container alignItems="center">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="subtitle1" fontWeight={600} color="primary.dark2">
          Plan
        </Typography>
        <SettingSidebar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      </Grid>
      {activePlan && <MyPlan />}

      {!activePlan && (
        <>
          <Stack alignItems={"center"}>
            <ToggleButtonGroup
              sx={{
                justifyContent: "center",
                background: "#F4F4FF",
                borderRadius: 35,
              }}
              value={alignment}
              exclusive
              onChange={handleAlignment}
              fullWidth
            >
              <ToggleButton value="left" selectedColor="#00abc0">
                Premium
              </ToggleButton>
              <ToggleButton value="center" selectedColor="#00abc0">
                Daily Boosters
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              {alignment === "left" ? <Premium /> : <Boosters />}
            </Grid>
            <Grid item xs={12} sm={4}>
              {alignment === "left" ? <Premium /> : <Boosters />}
            </Grid>
            <Grid item xs={12} sm={4}>
              {alignment === "left" ? <Premium /> : <Boosters />}
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}
