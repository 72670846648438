const style = (theme) => ({
  LoginBtn: {
    textTransform: "capitalize",
    width: "140px",
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.light,
    marginRight: "40px",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  loginNavBtn: {
    color: theme.palette.primary.light,
    marginLeft: "40px",
  },
  largeText: {
    fontSize: "70px",
    lineHeight: "80px",
    fontWeight: 600,
    color: theme.palette.primary.light,
  },
  customButtonSize: {
    height: "60px",
    width: "306px",
    fontSize: "18px",
    marginTop: "20px",
    background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
    color: theme.palette.primary.light,
    textTransform: "capitalize",
    borderRadius: "10px",
    textTransform: "capitalize",
  },
  gradientBtn: {
    background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
    color: theme.palette.primary.light,
    textTransform: "capitalize",
    borderRadius: "10px !important",
    textTransform: "capitalize !important",
    height: "60px",
    width: "100%",
  },
  socialIcon: {
    background: theme.palette.primary.light,
    maxWidth: "50px",
    maxHeight: "50px",
  },
  halfDivider: {
    borderBottom: "1.16871px dashed #059FD1 !important",
    opacity: "0.2",
    width: "30%",
  },
  // text colors
  white: {
    color: theme.palette.white + "!important",
  },
  black: {
    color: theme.palette.dark + "!important",
  },
  primary: {
    color: theme.palette.primary.main + "!important",
  },
  gray: {
    color: theme.palette.gray,
  },
  gray2: {
    color: theme.palette.gray2,
  },
  blue: {
    color: theme.palette.darkBlue + "!important",
  },
  lightBlue: {
    color: "rgba(11, 95, 218, 1) !important",
  },
  // backgrounds
  bgGradientDark: {
    background: "linear-gradient(167.65deg, #121422 -12.82%, #02106D 144.95%)",
  },
  bgGradientLight: {
    background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
  },
  bgWhite: {
    background: theme.palette.white + "!important",
  },
  // Divider
  divider: {
    borderColor: theme.palette.divider + "!important",
    opacity: "0.2",
  },
  dividerColor: {
    borderColor: "rgba(53, 185, 255, 1)" + "!important",
    opacity: "1",
  },
  dashedDivider: {
    border: "1px dashed #FFFFFF" + "!important",
    opacity: "0.2",
    margin: "18px 0px !important",
  },
  paymentDivider: {
    border: "1px solid #F0F0F0" + "!important",
  },
  listIcon: {
    color: "rgba(55, 179, 74, 1) !important",
  },
  // typography
  btnSmallTxt: {
    fontSize: "10px !important",
  },
  btnMediumTxt: {
    fontSize: "14px !important",
  },
  btnLargeTxt: {
    fontSize: "18px !important",
  },
  textFirst: {
    fontSize: "19.3517px",
    fontWeight: "500",
    lineHeight: "23.09px",
    color: theme.palette.grey,
  },
  textSecond: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "23.09px",
    color: theme.palette.dark,
  },
  gradientText: {
    fontSize: 20,
    background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  gradientText2: {
    fontSize: 18,
    background: "linear-gradient(167.83deg, #090839 -4.54%, #151755 113.49%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  planDate: {
    fontSize: "20px !important",
    color: theme.palette.darkBlue,
  },
  planMonth: {
    fontSize: "20px !important",
    background: "linear-gradient(167.83deg, #090839 -4.54%, #151755 113.49%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: 500,
    lineHeight: 24,
  },
  // Home Feed Style
  homeFeed: {
    margin: "30px 504px 30px 30px",
    width: "100%",
  },
  homeBanner: {
    width: "100%",
    height: "826px",
    borderRadius: "14px",
    overflow: "hidden",
    maxWidth: { xs: 350, md: "100%" },
  },
  homeImg: {
    width: "50%",
    height: "100%",
    objectFit: "cover",
  },
  // avator css
  avatarBg1: {
    background:
      "linear-gradient(4deg, rgb(163 161 161) 1.57%, rgb(196 196 196 / 52%) 100%), linear-gradient(0deg, #ffffff, #FFFFFF)",
    color: theme.palette.white + "!important",
    border: "2.93px solid #FFFFFF !important",
  },

  avatarBorderWhite: {
    border: "3px solid !important",
    borderColor: "rgba(255, 255, 255, 0.2) !important",
    borderRadius: "50% !important",
  },
  smallAvatar: {
    height: "64px !important",
    width: "64px !important",
    cursor: "pointer",
  },
  largeAvatar: {
    height: "91px !important",
    width: "89px !important",
    cursor: "pointer",
  },
  mediumAvatar: {
    height: "68px !important",
    width: "68px !important",
    cursor: "pointer",
  },
  smallBtn: {
    padding: "5px 10px !important",
    fontSize: "10px !important",
    gap: "4px !important",
    border: "none !important",
    backgroundColor: "#F0F0F0 !important",
    color: theme.palette.black.dark + "!important",
    textTransform: "capitalize !important",
  },
  smallBtnArrow: {
    color: "#0B5FDA",
  },

  // UerProfile
  sideBarButton: {
    background:
      "-webkit-linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%) !important",
    WebkitBackgroundClip: "text !important",
    WebkitTextFillColor: "transparent !important",
  },
  profilePrwBnt: {
    fontSize: 16,
    background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    border: "1px solid !important",
    borderColor: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
    height: "48px",
    maxWidth: "307px",
    width: "100%",
    borderRadius: "10px !important",
  },
  profilePrwChanges: {
    background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
    color: theme.palette.white + "!important",
    textTransform: "capitalize",

    maxWidth: "307px",
    width: "100%",
    height: "48px",
    borderRadius: "10px !important",
  },

  addtoCardBtn: {
    border: "1px solid rgba(220, 230, 251, 1) !important",
    color: "black" + "!important",
    textTransform: "capitalize !important",
    width: "100%",
    height: "48px",
    borderRadius: "10px !important",
    fontWeight: "400 !important",
  },
  chatInput: {
    height: 30,
    borderRadius: 15,
    padding: "0px !important",
    color: theme.palette.gray,
  },
  closeBtn: {
    background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
    color: theme.palette.white + "!important",
    fontSize: 10,
    position: "absolute !important",
    right: "-15px",
    top: "-10px",
    zIndex: "16 !important",
    border: "1px solid #FFFFFF !important",
  },
  closeBtn2: {
    background: theme.palette.white + "!important",
    color: theme.palette.primary.main + "!important",
    position: "absolute !important",
    right: "-10px",
    top: "-10px",
    zIndex: "16 !important",
    border: "1px solid #F3F3F3 !important",
    height: "20px",
    width: "20px",
  },
  // ChatBox
  chatBox: {
    marginLeft: "auto",
    width: "472px",
    position: "fixed",
    right: 0,
  },
  chatBoxHeight: {
    height: "calc(100vh - 80px)",
    position: "relative",
  },
  chatBoxAvatar: {
    width: "51px !important",
    height: "51px !important",
  },
  chatBoxAudioAvatar: {
    width: "47px !important",
    height: "47px !important",
  },
  sendMsg: {
    background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
    padding: "12px 14px",
    borderRadius: "14px 14px 0px 14px",
    mb: "3px",
    color: "white",
  },
  receivedMsg: {
    background: "#F9F6FF",
    padding: "12px 14px",
    borderRadius: "14px 14px 14px 0px",
    marginBottom: "12px",
  },
  cameraBtn: {
    background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
    height: "60px !important",
    width: "60px !important",
  },
  tab: {
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  formAreaInput: {
    padding: "13.5px 10px !important",
  },
  formControl: {
    "& .MuiInputBase-root": {
      color: "#6EC177",
      borderColor: "#6EC177",
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: "100px",
      minWidth: "120px",
      justifyContent: "center",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingRight: "0px",
    },
  },
  select: {
    width: "auto",
    fontSize: "12px",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  selectIcon: {
    position: "relative",
    color: "#6EC177",
    fontSize: "14px",
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      fontWeight: 200,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: "12px",
    },
    "& li.Mui-selected": {
      color: "white",
      background: "#6EC177",
    },
    "& li.Mui-selected:hover": {
      background: "#6EC177",
    },
  },
  stackStyle: {
    paddingTop: 34,
    paddingLeft: 13,
    paddingRight: 13,
    alignItems: "center",
  },
  typographyStyle: {
    color: theme.palette.primary.main,
  },
  btn: {
    backgroundColor: "rgb(255,255,255,0.2) !important",
    color: "white !important",
  },
  gridList: {
    width: "100%",
    height: "auto",
  },
  card: {
    maxWidth: 160,
    height: "100%",
  },
  // Avator1 js
  rightbarAvatar: {
    height: "70px !important",
    width: "70px !important",
  },
  swiper: {
    width: "100%",
    height: "100%",
    margin: "0 auto",
  },
  swiperImg: {
    display: "block",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  swiperSlide: {
    textAlign: "center",
    fontSize: "18px",
    background: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  toggleButton: {
    border: "none !important",
    maxWidth: "150px",
    width: "100%",
    height: "4px !important",
    padding: "0px !important",
    backgroundColor: "#D9D9D9 !important",
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      background: "linear-gradient(22.11deg, #35B9FF 22.54%, #7AD1FF 117.22%)",
    },
  },
  sendBtn: {
    background: theme.palette.priamrygradient,
    float: "right",
    height: "43px !important",
    width: "44px !important",
  },
  chatInputBox: {
    boxShadow: "0px 0px 20px 0px #00000012",
    padding: "8px 6px",
    borderRadius: "74px",
    width: "100%",
    background: theme.palette.white,
  },
  chatInput: {
    height: 30,
    borderRadius: 15,
    padding: "0px !important",
    color: theme.palette.gray,
  },
  modalStyle: {
    position: "absolute !important",
    borderRadius: "18px !important",
    top: "50% !important",
    left: "50% !important",
    transform: "translate(-50%, -50%) !important",
    width: 226 + "!important",
    backgroundColor: theme.palette.white + "!important",
    textAlign: "center",
    boxShadow: 24,
    padding: "32px 56px",
  },
  modalStyle2: {
    maxHeight: 500,
    overflowY: "scroll",
    position: "absolute !important",
    borderRadius: "20px !important",
    top: "50% !important",
    left: "50% !important",
    transform: "translate(-50%, -50%) !important",
    maxWidth: "641px" + "!important",
    width: "100%",
    textAlign: "center",
    boxShadow: 24,
    background: "#F9F6FF !important",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: theme.spacing(2.5),
      background: "#F9F6FF !important",
      "&::before": {
        content: '" "',
        position: "absolute",
        backgroundSize: "200px, 280px",
        backgroundImage: "url(/images/bg-1.png), url(/images/bg-2.png)",
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundPosition: "-57px -56px, 171% 106%",
        width: "100%",
        height: "100%",
        zIndex: "-2",
      },
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(2) + " 0",
    },
  },
  mdlBtnCancel: {
    textTransform: "capitalize !important",
    border: "1px solid !important",
    borderImageSlice: "1 !important",
    borderImageSource:
      "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%) !important",
    borderRadius: "10px !important",
  },
  modalbg1: {
    position: "absolute",
    top: "-45px",
    left: "-28px",
    zIndex: "-2",
  },
  modalbg2: {
    position: "absolute",
    bottom: "-150px",
    right: "-108px",
    zIndex: "-2",
  },

  textField: {
    width: "100%",
    boxShadow: "0px 10px 21px rgba(19, 15, 38, 0.06)",
    borderRadius: "14px !important",
    background: "none !important",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  input: {
    "& .MuiInputBase-root": {
      border: "1px solid #E4E3E3",
      background: theme.palette.white,
      borderRadius: theme.spacing(1.25),
      "& .MuiInputBase-input": {
        background:
          "linear-gradient(167.83deg, #090839 -4.54%, #151755 113.49%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        height: "55px",
        boxSizing: "border-box",
        fontWeight: "400",
        "&::placeholder": {
          background: "rgba(161, 159, 168, 1)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          opacity: "1",
          fontWeight: "300",
          fontSize: "14px",
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "& .Mui-focused": {
      border: "2px solid #0B5FDA",
    },
  },
  selectInput: {
    "& .MuiInputBase-root": {
      border: "1px solid #E4E3E3",
      background: theme.palette.white,
      borderRadius: "0 14px 14px 0px",
      "& .MuiInputBase-input": {
        background:
          "linear-gradient(167.83deg, #090839 -4.54%, #151755 113.49%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        height: "55px",
        boxSizing: "border-box",
        fontWeight: "400",
        "&::placeholder": {
          background: "rgba(161, 159, 168, 1)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          opacity: "1",
          fontWeight: "300",
          fontSize: "14px",
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },
      "& .Mui-focused": {
        border: "none !important",
      },
    },
  },

  textField2: {
    width: "100%",
    boxShadow: "0px 10px 21px rgba(19, 15, 38, 0.06)",
    background: "none !important",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  input2: {
    background: theme.palette.white + "!important",
    borderRadius: "0px 14px 14px 0px !important",
    border: "1px solid rgba(228, 227, 227, 1) !important",
    "&::placeholder": {
      color: "black !important",
    },
  },
  selectField: {
    boxShadow: "0px 10px 21px rgba(19, 15, 38, 0.06)",
    background: "none !important",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiSelect-select": {
      background: theme.palette.white + "!important",
      borderRadius: "14px 0px 0px 14px !important",
      border: "1px solid rgba(228, 227, 227, 1) !important",
    },
  },

  otpField: {
    width: "100%",
    boxShadow: "0px 10px 21px rgba(19, 15, 38, 0.06)",
    background: "none !important",
    borderRadius: "14px !important",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  otpInput: {
    background: theme.palette.white + "!important",
    borderRadius: "14px !important",
    fontSize: "40px !important",
    width: 70,
    height: 70,
    textAlign: "center",
    border: "1px solid rgba(228, 227, 227, 1) !important",
    "&::placeholder": {
      color: "black !important",
    },
    "&:focus-visible": {
      outline: "#0a68d8 auto 1px",
    },
  },
  blueDivider: {
    borderBottom: "1.16871px dashed #059FD1 !important",
    opacity: "0.2",
  },
  backBtn: {
    textTransform: "capitalize !important",
    color: theme.palette.white + "!important",
    background: "linear-gradient(167.65deg, #121422 -12.82%, #02106D 144.95%)",
    borderRadius: "0px 10px 10px 0px !important",
    padding: "18px 42px !important",
  },
  nextBtn: {
    textTransform: "capitalize !important",
    color: theme.palette.white + "!important",
    background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
    borderRadius: "10px 0px 0px 10px !important",
    padding: "18px 42px !important",
  },
  genderAvatar: {
    width: "100% !important",
    height: "100% !important",
  },
  genderBtn: {
    width: "100% !important",
    height: "100% !important",
    maxHeight: 120,
    maxWidth: 120,
  },
  selectBox: {
    background: "#FFFFFF",
    border: "1px solid #E4E3E3",
    borderRadius: "10px",
    padding: "7.50px 15px",
    "& .MuiTypography-root": {
      color: theme.palette.darkBlue,
      fontSize: 14,
    },
  },
  addPhoto: {
    background: "#F9F6FF",
    border: "1px dashed #059FD1 !important",
    height: "274px",
    width: "100%",
    borderRadius: "5px !important",
  },
  uploadLargeIcon: {
    color: "rgba(11, 95, 218, 1)",
    fontSize: "60px !important",
  },
  uploadSmallIcon: {
    color: "rgba(11, 95, 218, 1)",
    fontSize: "28px !important",
  },
  skipBtn: {
    color: theme.palette.darkBlue + "!important",
    textTransform: "capitalize !important",
    padding: "0 !important",
  },
  radioBtn: {
    borderRadius: "50% !important",
    cursor: "pointer",
    color: "#0c59db !important",
  },
  iconRadioBtn: {
    borderRadius: "50% !important",
    cursor: "pointer",
    color: "#0c59db !important",
    height: theme.spacing(13),
    width: theme.spacing(13),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  hiddenRadio: {
    background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
    color: theme.palette.white + "!important",
  },
  hiddenBg: {
    background: "linear-gradient(22.11deg, #35B9FF 22.54%, #7AD1FF 117.22%) ",
    color: theme.palette.white + "!important",
  },
  radioImg: {
    height: 120,
    width: 120,
    borderRadius: "50%",
    marginBottom: "-4px",
  },
  textField: {
    "& .MuiInputBase-root": {
      borderRadius: theme.spacing(1.25),
      border: `2px solid ${theme.palette.gray.main}`,
      background: theme.palette.primary.main,
      height: "55px",
      boxSizing: "border-box",
    },
  },
  commentBox: {
    border: `2px solid ${theme.palette.gray.main}`,
    borderRadius: "10px",
    padding: "15px",
    "&.Mui-focused": {
      border: `2px solid ${theme.palette.secondary.main}`,
    },
  },
});

export default style;
