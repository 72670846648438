import * as React from "react";
import PropTypes from "prop-types";
import style from "themes/styles";
import { withStyles } from "@mui/styles";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Button,
  Stack,
} from "@mui/material";
import ProgressBar from "Components/ProgressBar";

import { GET_PARAMS, GET_ENUMS } from "PopupRoutes/components/settings";
import usePrepareLink from "hooks/usePrepareLink";
import { useNavigate } from "react-router-dom";
import CustomCheckBox from "Components/CustomCheckBox";

const Ethnicity = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const nextPage = usePrepareLink({
    query: {
      [GET_PARAMS.popup]: GET_ENUMS.popup.orientation,
    },
  });
  const prvPage = usePrepareLink({
    query: {
      [GET_PARAMS.popup]: GET_ENUMS.popup.dob,
    },
  });
  const HandleNext = () => {
    navigate(nextPage);
  };

  const HandleBack = () => {
    navigate(prvPage);
  };

  return (
    <>
      <DialogTitle>
        <ProgressBar value={25} />
      </DialogTitle>
      <DialogContent>
        <Box my={3} textAlign="center">
          <Typography
            color="secondary"
            variant="subtitle2"
            fontWeight="500"
            mb={1}
          >
            Lets setup your profile!
          </Typography>
          <Typography color="primary" variant="subtitle1" fontWeight="700">
            My Ethnicity is:
          </Typography>
        </Box>
        <Stack spacing="20px" mx={3} mb={3}>
          <CustomCheckBox label="African Americans" />
          <CustomCheckBox label="Caucasian/white" />
          <CustomCheckBox label="Hispanic/Latino" />
          <CustomCheckBox label="Native American" />
          <CustomCheckBox label="Asian" />
          <CustomCheckBox label="Caribbean/West Indian" />
          <CustomCheckBox label="Middle eastern" />
          <CustomCheckBox label="Pacific islander" />
          <CustomCheckBox label="Other" />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack flex={1} justifyContent="space-between" direction="row">
          <Button onClick={() => HandleBack()} className={classes.backBtn}>
            Back
          </Button>
          <Button onClick={() => HandleNext()} className={classes.nextBtn}>
            Next
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};

Ethnicity.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Ethnicity);
