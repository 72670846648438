import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import IconButton from "@mui/material/IconButton";
import WhatshotRoundedIcon from "@mui/icons-material/WhatshotRounded";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { withStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import style from "themes/styles";

const UserProfile = (props) => {
  const { classes } = props;
  return (
    <Stack spacing={3} alignItems={"center"}>
      <Stack direction="column" spacing={0} className={classes.profileShow}>
        <Stack
          className={classes.profileShowGradient}
          alignItems="center"
          direction="column"
          justifyContent="center"
        >
          <Stack
            sx={{
              marginTop: "-45px",
              width: 1,
              height: "110px",
              gap: "20px",
            }}
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <IconButton className={classes.smallIconBg} aria-label="delete">
              <DeleteOutlinedIcon color="primary" />
            </IconButton>
            <IconButton className={classes.bigIconBg} aria-label="delete">
              <WhatshotRoundedIcon color="primary" />
            </IconButton>
            <IconButton className={classes.smallIconBg} aria-label="delete">
              <WhatshotRoundedIcon color="primary" />
            </IconButton>
          </Stack>
          <Stack
            sx={{
              width: 1,
            }}
            justifyContent={"center"}
          >
            <Box p={2}>
              <Stack direction={"row"} alignItems="center" spacing={1}>
                <Typography variant="body2" fontWeight="500" color="primary">
                  Lindsey Levin,20
                </Typography>
                <Button
                  classes={{
                    root: classes.btn,
                  }}
                >
                  <FmdGoodOutlinedIcon />
                  Delete
                </Button>
              </Stack>
              <Typography variant="body2" color="primary" fontWeight="400">
                @Lindsey
              </Typography>
              <Typography variant="body1" color="primary" fontWeight="400">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit Justo.
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Stack>
      <Box
        width={1}
        px={2}
        sx={{
          maxWidth: "814px",
          margin: "0 auto",
        }}
      >
        <Typography variant="subtitle2" color="primary.dark2">
          Allow me to introduce myself.
        </Typography>
        <Typography variant="body1" component="p" mb={3} color="gray.main">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also
        </Typography>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Card sx={{ boxShadow: 0 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="330"
                    image="https://images.freeimages.com/images/large-previews/f5b/a-child-1431845.jpg"
                    alt="green iguana"
                    sx={{
                      borderRadius: "10px",
                    }}
                  />
                  <CardContent>
                    <Stack spacing={2}>
                      <Box>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems={"center"}
                          mb={"6px"}
                        >
                          <Box
                            component="img"
                            className={classes.uploadImg}
                            alt="The house from the offer."
                            src="/images/profilePage_icon/ethnicity.png"
                          />
                          <Typography
                            gutterBottom
                            color="primary.dark2"
                            variant="body1"
                          >
                            My Ethnicity is:
                          </Typography>
                        </Stack>
                        <Typography sx={{ ml: 4 }} variant="p" color="gray">
                          Hetrosexual
                        </Typography>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems={"center"}
                          mb={"6px"}
                        >
                          <Box
                            component="img"
                            className={classes.uploadImg}
                            alt="The house from the offer."
                            src="/images/profilePage_icon/relationship.png"
                          />
                          <Typography
                            gutterBottom
                            color="primary.dark2"
                            variant="body1"
                          >
                            My sexual orientation is:
                          </Typography>
                        </Stack>
                        <Typography sx={{ ml: 4 }} variant="p" color="gray">
                          Hetrosexual
                        </Typography>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems={"center"}
                          mb={"6px"}
                        >
                          <Box
                            component="img"
                            className={classes.uploadImg}
                            alt="The house from the offer."
                            src="/images/profilePage_icon/ethnicity.png"
                          />
                          <Typography
                            gutterBottom
                            color="primary.dark2"
                            variant="body1"
                          >
                            Relationship Goals
                          </Typography>
                        </Stack>
                        <Typography sx={{ ml: 4 }} variant="p" color="gray">
                          Engage
                        </Typography>
                      </Box>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card sx={{ boxShadow: 0 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="330"
                    image="https://images.freeimages.com/variants/hGje8oXQPYzKXjx8kaYiRmaN/5a0bd68e593adb18739fe6fe526aa40368c6d1e5924c85e47ba06f7a665f1000"
                    alt="green iguana"
                    sx={{
                      borderRadius: "10px",
                    }}
                  />
                  <CardContent>
                    <Stack spacing={2}>
                      <Box>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems={"center"}
                          mb={"6px"}
                        >
                          <Box
                            component="img"
                            className={classes.uploadImg}
                            alt="The house from the offer."
                            src="/images/profilePage_icon/ethnicity.png"
                          />
                          <Typography
                            gutterBottom
                            color="primary.dark2"
                            variant="body1"
                          >
                            Level of Education
                          </Typography>
                        </Stack>
                        <Typography sx={{ ml: 4 }} variant="p" color="gray">
                          PHD
                        </Typography>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems={"center"}
                          mb={"6px"}
                        >
                          <Box
                            component="img"
                            className={classes.uploadImg}
                            alt="The house from the offer."
                            src="/images/profilePage_icon/ethnicity.png"
                          />
                          <Typography
                            gutterBottom
                            color="primary.dark2"
                            variant="body1"
                          >
                            Organization Affiliation
                          </Typography>
                        </Stack>
                        <Typography sx={{ ml: 4 }} variant="p" color="gray">
                          ELK
                        </Typography>
                      </Box>
                      <Box>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems={"center"}
                          mb={"6px"}
                        >
                          <Box
                            component="img"
                            className={classes.uploadImg}
                            alt="The house from the offer."
                            src="/images/profilePage_icon/ethnicity.png"
                          />
                          <Typography
                            gutterBottom
                            color="primary.dark2"
                            variant="body1"
                          >
                            Height
                          </Typography>
                        </Stack>
                        <Typography sx={{ ml: 4 }} variant="p" color="gray">
                          Engage
                        </Typography>
                      </Box>
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Stack>
  );
};

export default withStyles(style)(UserProfile);
