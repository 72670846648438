import * as React from "react";
import PropTypes from "prop-types";
import style from "themes/styles";
import classNames from "classnames";
import { withStyles } from "@mui/styles";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
  Button,
  Box,
  Stack,
} from "@mui/material";

import ProgressBar from "Components/ProgressBar";
import { GET_PARAMS, GET_ENUMS } from "PopupRoutes/components/settings";
import usePrepareLink from "hooks/usePrepareLink";
import { useNavigate } from "react-router-dom";
import { femaleIcon, maleIcon } from "assets/images";

const SelectGender = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const nextPage = usePrepareLink({
    query: {
      [GET_PARAMS.popup]: GET_ENUMS.popup.dob,
    },
  });
  const prvPage = usePrepareLink({
    query: {
      [GET_PARAMS.popup]: GET_ENUMS.popup.name,
    },
  });
  const HandleNext = () => {
    navigate(nextPage);
  };

  const HandleBack = () => {
    navigate(prvPage);
  };

  const [gender, setGender] = React.useState("");

  return (
    <>
      <DialogTitle>
        <ProgressBar value={15} />
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <Box my={4}>
          <Typography
            variant="subtitle2"
            color="secondary"
            fontWeight="500"
            mb={1}
          >
            Lets setup your profile!
          </Typography>
          <Typography color="primary" variant="subtitle1" fontWeight="700">
            I am a
          </Typography>
        </Box>
        <Stack justifyContent="center" direction="row" spacing={3}>
          <Box>
            <Paper
              onClick={() => setGender("Male")}
              className={classNames(
                classes.circleBox,
                gender === "Male" && classes.gradientBtn
              )}
              elevation={3}
            >
              <Box component={"img"} alt={"Male"} src={maleIcon} />
            </Paper>
            <Typography color="secondary" variant="caption">
              Male
            </Typography>
          </Box>
          <Box>
            <Paper
              onClick={() => setGender("Female")}
              className={classNames(
                classes.circleBox,
                gender === "Female" && classes.gradientBtn
              )}
              elevation={3}
            >
              <Box component={"img"} alt={"Female"} src={femaleIcon} />
            </Paper>
            <Typography color="secondary" variant="caption">
              Female
            </Typography>
          </Box>
        </Stack>
        <Typography
          onClick={() => HandleNext()}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
          variant="body2"
          className={classes.gradientText}
          fontWeight="500"
          my={5}
        >
          I do not want to declare.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Stack flex={1} justifyContent="space-between" direction="row">
          <Button onClick={() => HandleBack()} className={classes.backBtn}>
            Back
          </Button>
          <Button onClick={() => HandleNext()} className={classes.nextBtn}>
            Next
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};

SelectGender.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(SelectGender);
