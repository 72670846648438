import * as React from "react";
import PropTypes from "prop-types";
import style from "themes/styles";
import { withStyles } from "@mui/styles";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Button,
  Box,
  Stack,
} from "@mui/material";
import ProgressBar from "Components/ProgressBar";

import { GET_PARAMS, GET_ENUMS } from "PopupRoutes/components/settings";
import usePrepareLink from "hooks/usePrepareLink";
import { useNavigate } from "react-router-dom";

const ProfileSet = (props) => {
  const { classes } = props;

  const navigate = useNavigate();
  const nextPage = usePrepareLink({
    query: {
      [GET_PARAMS.popup]: GET_ENUMS.popup.gender,
    },
  });
  const HandleContinue = () => {
    navigate(nextPage);
  };

  return (
    <>
      <DialogTitle>
        <ProgressBar value={10} />
      </DialogTitle>
      <DialogContent>
        <Box my={3} textAlign="center">
          <Typography
            color="secondary"
            variant="subtitle2"
            fontWeight="500"
            mb={1}
          >
            Lets setup your profile!
          </Typography>
          <Typography color="primary" variant="subtitle1" fontWeight="700">
            My Name Is
          </Typography>
        </Box>
        <Stack spacing={2} mx={4}>
          <TextField placeholder="First  Name" />
          <TextField placeholder="Last Name" />
          <TextField placeholder="Nick Name" />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack flex={1} justifyContent="space-between" direction="row">
          <Button className={classes.backBtn}>Back</Button>
          <Button onClick={() => HandleContinue()} className={classes.nextBtn}>
            Next
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};

ProfileSet.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(ProfileSet);
