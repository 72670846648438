import React from "react";
import { Box, IconButton } from "@mui/material";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SET_THEME_NAME } from "store/actions";

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customization = useSelector((state) => state.customization);

  let themeName = customization.themeName;

  const logout = () => {
    themeName = "darkBlue";
    localStorage.setItem("themeName", themeName);
    dispatch({ type: SET_THEME_NAME, themeName });
    navigate("/");
  };

  return (
    <Box>
      <IconButton onClick={() => logout()}>
        <NotificationsNoneOutlinedIcon />
      </IconButton>
    </Box>
  );
};

export default NotificationSection;
