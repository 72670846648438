import React from "react";
import { Route, Routes } from "react-router-dom";
import GuestAppLayout from "layout/GuestAppLayout";
import MainAppLayout from "layout/MainAppLayout";
import LandingPage from "pages/Landing";
//import { useSelector } from "react-redux";
import Home from "pages/App/Home";
import Feed from "pages/App/Feed";
import Profile from "pages/App/Profile";
import ProfilePreview from "pages/App/Profile/ProfilePreview";
import Setting from "pages/App/Setting";
import Chat from "pages/App/Chat";
import EditProfile from "pages/App/EditProfile";

const GuestLayout = () => {
  return (
    <Routes>
      <Route path={"/"} element={<GuestAppLayout />}>
        <Route exact path={"/"} element={<LandingPage />} />
      </Route>
    </Routes>
  );
};
const AppLayout = () => {
  return (
    <Routes>
      <Route path={"/"} element={<MainAppLayout />}>
        <Route index path={"/"} element={<Home />} />
        <Route path={"feed"} element={<Feed />} />
        <Route path={"profile"} element={<Profile />} />
        <Route path={"setting/*"} element={<Setting />} />
        <Route path={"chat"} element={<Chat />} />
        <Route path={"profile-edit"} element={<EditProfile />} />
        <Route path={"profile/preview-profile"} element={<ProfilePreview />} />
      </Route>
    </Routes>
  );
};

function App() {
  //const customization = useSelector((state) => state.customization);
  //let themeName = customization.themeName;

  return (
    <Routes>
      <Route exact path={"/"} element={<GuestLayout />} />
      <Route path={"/app/*"} element={<AppLayout />} />
    </Routes>
  );
}

export default App;
