import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Box, Fab } from "@mui/material";
import style from "themes/appStyles";
import { withStyles } from "@mui/styles";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import ProfileTabs from "./ProfileTabs";

function EditProfile(props) {
  const { classes } = props;
  return (
    <Box>
      <Card sx={{ position: "relative" }}>
        <CardMedia
          component="img"
          height="350"
          image="https://mui.com/static/images/cards/contemplative-reptile.jpg"
          alt="green iguana"
        />
        <Fab
          color="primary"
          aria-label="add"
          className={classes.cameraBtn}
          sx={{ position: "absolute", top: "320px", right: "37px" }}
        >
          <CameraAltOutlinedIcon />
        </Fab>
        <CardContent sx={{ maxWidth: "814px", margin: "0 auto" }}>
          <ProfileTabs />
        </CardContent>
      </Card>
    </Box>
  );
}

export default withStyles(style)(EditProfile);
