import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import style from "themes/styles";
import { useNavigate } from "react-router-dom";
import Logo from "Components/Logo";
import {
  HomeOutlined,
  PersonOutlineOutlined,
  ManageAccountsOutlined,
  DatasetOutlined,
  SpeakerNotesOutlined,
} from "@mui/icons-material";
import { ListItemIcon } from "@mui/material";

const drawerWidth = 240;
const navItems = [
  { label: "Home", url: "/app", icon: <HomeOutlined /> },
  { label: "My Feed", url: "/app/feed", icon: <DatasetOutlined /> },
  { label: "Profile", url: "/app/profile", icon: <PersonOutlineOutlined /> },
  { label: "Setting", url: "/app/setting", icon: <ManageAccountsOutlined /> },
  { label: "message", url: "/app/chat", icon: <SpeakerNotesOutlined /> },
];

function MainNavbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const goToPage = (page) => {
    navigate(page);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box my={2}>
        <Logo />
      </Box>
      <Divider />
      <List>
        {navItems.map((item, i) => (
          <ListItem key={"drawer-menu-" + item.label + "-" + i} disablePadding>
            <ListItemButton
              sx={{ textAlign: "start" }}
              onClick={() => goToPage(item.url)}
            >
              <ListItemIcon variant="body2" color="gray">
                {item.icon}
              </ListItemIcon>
              <ListItemText variant="body2" color="gray" primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box flex={1}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { sm: "none" } }}
      >
        <MenuIcon />
      </IconButton>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        {navItems.map((item, i) => (
          <Button
            color="gray"
            key={"header-menu-" + item.label + "-" + i}
            sx={{ ml: 2 }}
            onClick={() => goToPage(item.url)}
            startIcon={item.icon}
          >
            <Typography variant="menuCaption">{item.label}</Typography>
          </Button>
        ))}
      </Box>

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
MainNavbar.propTypes = {
  classes: PropTypes.object.isRequired,
  window: PropTypes.any,
};
export default withStyles(style)(MainNavbar);
