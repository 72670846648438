export default function componentStyleOverrides(theme) {
  const customization = theme.customization;
  return {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background:
            customization.themeName === "darkBlue"
              ? "url(./images/main-bg.png)"
              : theme.palette.background.default,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            borderRadius: theme.spacing(1.25),
            border: `2px solid ${theme.palette.gray.main}`,
            background: theme.palette.primary.light,
            height: "55px",
            boxSizing: "border-box",
          },
          "& .MuiInputBase-root.Mui-focused": {
            border: `2px solid ${theme.palette.secondary.main}`,
          },
          "& .MuiInputBase-input": {
            background:
              "linear-gradient(167.83deg, #090839 -4.54%, #151755 113.49%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontWeight: "400",
            "&::placeholder": {
              background: theme.palette.gray.light,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              opacity: "1",
              fontWeight: "300",
              fontSize: "14px",
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        },
      },
    },
  };
}
