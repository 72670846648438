import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import style from "themes/styles";
import { withStyles } from "@mui/styles";
import { Stack } from "@mui/system";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from "@mui/material";
import ProgressBar from "Components/ProgressBar";
import TransgenderRoundedIcon from "@mui/icons-material/TransgenderRounded";
import MaleRoundedIcon from "@mui/icons-material/MaleRounded";
import FemaleRoundedIcon from "@mui/icons-material/FemaleRounded";

import { GET_PARAMS, GET_ENUMS } from "../../PopupRoutes/components/settings";
import usePrepareLink from "../../hooks/usePrepareLink";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

const Interest = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const nextPage = usePrepareLink({
    query: {
      [GET_PARAMS.popup]: GET_ENUMS.popup.finish,
    },
  });
  const prvPage = usePrepareLink({
    query: {
      [GET_PARAMS.popup]: GET_ENUMS.popup.photos,
    },
  });
  const HandleNext = () => {
    navigate(nextPage);
  };

  const HandleBack = () => {
    navigate(prvPage);
  };

  const [interest, setInterest] = React.useState(null);

  return (
    <>
      <DialogTitle>
        <ProgressBar value={45} />
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <Box my={3}>
          <Typography
            sx={{ maxWidth: "310px", margin: "0 auto" }}
            color="secondary"
            variant="subtitle2"
            fontWeight="500"
            mb={1}
          >
            Excellent, Now tell us about your Interest.
          </Typography>
        </Box>
        <Stack
          justifyContent="center"
          direction="row"
          spacing={2}
          mt={5}
          mb={10}
        >
          <Box>
            <Paper
              onClick={() => setInterest("Male")}
              className={classNames(
                classes.circleBox,
                interest === "Male" && classes.gradientBtn
              )}
              elevation={3}
            >
              <MaleRoundedIcon sx={{ fontSize: 55 }} />
            </Paper>
            <Typography color="secondary" variant="caption">
              Male
            </Typography>
          </Box>
          <Box>
            <Paper
              onClick={() => setInterest("Female")}
              className={classNames(
                classes.circleBox,
                interest === "Female" && classes.gradientBtn
              )}
              elevation={3}
            >
              <FemaleRoundedIcon sx={{ fontSize: 55 }} />
            </Paper>
            <Typography color="secondary" variant="caption">
              Female
            </Typography>
          </Box>
          <Box>
            <Paper
              onClick={() => setInterest("Both")}
              className={classNames(
                classes.circleBox,
                interest === "Both" && classes.gradientBtn
              )}
              elevation={3}
            >
              <TransgenderRoundedIcon sx={{ fontSize: 55 }} />
            </Paper>
            <Typography color="secondary" variant="caption">
              Both
            </Typography>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack flex={1} justifyContent="space-between" direction="row">
          <Button onClick={() => HandleBack()} className={classes.backBtn}>
            Back
          </Button>
          <Button onClick={() => HandleNext()} className={classes.nextBtn}>
            Next
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};

Interest.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Interest);
