import React from "react";
import PropTypes from "prop-types";
import {
  Stack,
  Grid,
  Box,
  Paper,
  Typography,
  Avatar,
  IconButton,
  Chip,
} from "@mui/material";

import style from "themes/styles";
import { withStyles } from "@mui/styles";
import TabPanel from "Components/RightBar/TabPanel";
import MoreVertTwoToneIcon from "@mui/icons-material/MoreVertTwoTone";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import WhatshotRoundedIcon from "@mui/icons-material/WhatshotRounded";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import useWindowDimensions from "utils/useWindowDimensions";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    id: 1,
    label: "Cooper Baptista",
    imgPath:
      "https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60",
  },
  {
    id: 2,
    label: "Jake Pratt",
    imgPath:
      "https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60",
  },
  {
    id: 3,
    label: "Jessica Smith",
    imgPath:
      "https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250",
  },
  {
    id: 4,
    label: "Anna Durand",
    imgPath:
      "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
  },
];

const Feed = (props) => {
  const { classes } = props;
  const { height } = useWindowDimensions();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const renderStepper = () => {
    return (
      <Box className={classes.sliderDots}>
        {images.map((step, index) => (
          <Chip
            disabled={index !== activeStep}
            key={"stepper-btn-" + index}
          ></Chip>
        ))}
      </Box>
    );
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={2}></Grid>
      <Grid item xs={12} sm={7}>
        <Box className={classes.contentBox}>
          {[
            "/images/Frame_1.png",
            "/images/Frame_2.png",
            "/images/Frame_3.png",
          ].map((item, index) => {
            return (
              <Box
                key={"my-feed-" + index}
                className={classes.feedContainer}
                sx={{ height: `${height - 80}px !important` }}
              >
                <AutoPlaySwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={activeStep}
                  onChangeIndex={handleStepChange}
                  enableMouseEvents
                >
                  {images.map((step, index) => {
                    return Math.abs(activeStep - index) <= 2 ? (
                      <Box
                        key={step.label}
                        component="img"
                        className={classes.feedImg}
                        src={step.imgPath}
                        alt={step.label}
                      />
                    ) : null;
                  })}
                </AutoPlaySwipeableViews>

                <Box className={classes.feedImgDetails}>
                  <Typography variant="subtitle2" color="primary">
                    {images[activeStep].label}
                  </Typography>
                  <Typography
                    variant="body2"
                    s
                    component="p"
                    color="primary"
                    fontWeight={400}
                    mb={1}
                  >
                    @Lindsey
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    color="primary"
                    fontWeight={400}
                    mb={3}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Justo.
                  </Typography>

                  <Stack
                    spacing={2}
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Avatar
                      className={classes.transIcon}
                      alt="Remy Sharp"
                      src="https://mui.com/static/images/avatar/1.jpg"
                    />
                    <IconButton
                      aria-label="delete"
                      className={classes.transIcon}
                    >
                      <MoreVertTwoToneIcon />
                    </IconButton>
                    <IconButton
                      className={classes.transIcon}
                      aria-label="delete"
                    >
                      <DeleteOutlinedIcon />
                    </IconButton>
                    <IconButton
                      className={classes.transIcon}
                      aria-label="delete"
                    >
                      <WhatshotRoundedIcon />
                    </IconButton>
                    <IconButton
                      className={classes.transIcon}
                      aria-label="delete"
                    >
                      <WhatshotRoundedIcon />
                    </IconButton>
                  </Stack>
                </Box>

                {renderStepper()}
                <MobileStepper
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  variant=""
                  classes={{
                    root: classes.sliderRoot,
                    dotActive: classes.sliderDotActive,
                  }}
                  nextButton={
                    <Button
                      size="large"
                      color="gray"
                      onClick={handleNext}
                      disabled={activeStep === maxSteps - 1}
                    >
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  }
                  backButton={
                    <Button
                      size="large"
                      color="gray"
                      onClick={handleBack}
                      disabled={activeStep === 0}
                    >
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                      ) : (
                        <KeyboardArrowLeft />
                      )}
                    </Button>
                  }
                />
              </Box>
            );
          })}
        </Box>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper elevation={0} square>
          <TabPanel />
        </Paper>
      </Grid>
    </Grid>
  );
};
Feed.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(style)(Feed);
