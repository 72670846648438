import React from "react";
import { Box } from "@mui/material";
import MainNavbar from "../../Components/MainNavbar";
import { Outlet } from "react-router-dom";

// ==============================|| MINIMAL LAYOUT ||============================== //

const GuestAppLayout = () => (
  <Box sx={{ height: "100vh" }}>
    <MainNavbar />
    <Box alignItems={"center"}>
      <Outlet />
    </Box>
  </Box>
);

export default GuestAppLayout;
