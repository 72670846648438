import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack } from "@mui/system";
import { withStyles } from "@mui/styles";
import style from "themes/appStyles";

function BlackModal(props) {
  const { openModal, closeModal, classes } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modalStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className={classes.gradientText}
          >
            Block Cooper Baptista
          </Typography>
          <Typography
            id="modal-modal-description"
            className={classes.gradientText}
            sx={{
              fontWeight: "400",
              fontSize: "11.39px",
              mt: "20px",
              mb: "27px",
            }}
          >
            uis bibendum dui eu mollis condimentum. In in mauris eget ...
          </Typography>
          <Stack direction="row" spacing={1}>
            <Button
              className={[classes.mdlBtnCancel, classes.gradientText]}
              onClick={closeModal}
              fullWidth
            >
              Primary
            </Button>
            <Button
              className={[classes.mdlBtnBlock]}
              variant="contained"
              onClick={closeModal}
              fullWidth
            >
              Block
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
export default withStyles(style)(BlackModal);
