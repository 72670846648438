import React from "react";
import PropTypes from "prop-types";
import { Box, Stack, Typography, Button } from "@mui/material";
import style from "themes/styles";
import { withStyles } from "@mui/styles";
import PopupRoutes from "PopupRoutes";
import { GET_PARAMS, GET_ENUMS } from "PopupRoutes/components/settings";
import usePrepareLink from "hooks/usePrepareLink";
import { useNavigate } from "react-router-dom";
import { heartLogo } from "assets/images";

function LandingPage(props) {
  const { classes } = props;
  const navigate = useNavigate();
  const signInLink = usePrepareLink({
    query: {
      [GET_PARAMS.popup]: GET_ENUMS.popup.signIn,
    },
  });

  const HandleContinue = () => {
    navigate(signInLink, { state: { title: false, close: true } });
  };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ height: "calc(100vh - 64px)" }}
    >
      <Stack
        sx={{ maxWidth: "676px", textAlign: "center", alignItems: "center" }}
      >
        <Box
          component={"img"}
          src={heartLogo}
          sx={{ width: 100, height: 100 }}
        />
        <Typography variant="h1" color="primary.light">
          Find Your Partner With Us
        </Typography>
        <Button
          onClick={() => HandleContinue()}
          className={classes.customButtonSize}
        >
          Continue
        </Button>
      </Stack>
      <PopupRoutes />
    </Stack>
  );
}
LandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(style)(LandingPage);
