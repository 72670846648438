import { Box, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import SettingSidebar from "./SettingSidebar";

const PrivacyPolicy = (props) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = (props) => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <Box>
      <Grid container alignItems="center">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="subtitle1" fontWeight={600} color="primary.dark2">
          Privacy Policy
        </Typography>
        <SettingSidebar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      </Grid>
      <Typography variant="body2" color="gray.main" fontWeight={400}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc, quam
        purus vitae suscipit dictum elementum adipiscing in orci. Amet elit
        lorem viverra gravida at odio vitae dictum. Lacinia amet suscipit fames
        ultricies amet nec. Facilisi neque amet aliquet sed vulputate. Amet
        volutpat tellus, est amet, bibendum nam justo. Adipiscing aenean odio
        sed vitae in et. Amet, quam orci euismod velit scelerisque dictum enim.
        Sit nulla egestas morbi ultricies. Tristique pellentesque porta luctus
        eu nunc tellus. Arcu pellentesque eu dapibus platea nulla. Mauris
        convallis feugiat ornare sed bibendum est viverra in magna. Netus
        pellentesque elementum pretium eget dui. Quis erat egestas in porttitor
        id. Amet semper iaculis aliquet duis at pulvinar. Nunc rhoncus
        consectetur mattis vestibulum, facilisis vulputate in nec. Tempor etiam
        ut nullam enim. Cursus pharetra, id adipiscing aliquet volutpat porta.
        Aenean orci, morbi urna, velit eleifend quis elementum. Vel eget
        bibendum ultricies purus. Molestie pretium cursus sagittis, vitae odio.
        Massa sit gravida mi vel elit diam nisi. Id nascetur massa adipiscing
        leo urna, aliquam. Consectetur tristique fermentum nullam dignissim. Est
        pellentesque diam diam adipiscing. Enim euismod metus, ultricies nulla
        congue et sollicitudin faucibus ut. Scelerisque aenean libero amet
        vulputate vel praesent. Commodo lorem quis posuere tincidunt facilisi
        consectetur pharetra cras. Nisl scelerisque pharetra amet enim, vel
        venenatis elit. Risus viverra risus tristique elementum et tempus. Id
        lectus ornare facilisi enim risus in. Nunc morbi ultrices id vel fusce
        hac cursus gravida. Tincidunt condimentum in non posuere neque aliquam
        lacus augue. Eget sit est tellus massa quis pretium suspendisse. Eu
        suspendisse in tincidunt ornare quisque consectetur enim. Tincidunt at
        eget nulla turpis consequat sit purus. Tristique elit dolor enim leo
        dignissim interdum. Fames magna nunc lorem neque, et iaculis ac
        faucibus. Egestas ipsum, non elit congue vulputate vel urna. Sem donec
        vestibulum eget imperdiet magna id. Pellentesque arcu placerat
        scelerisque viverra orci viverra adipiscing. Commodo enim vitae nam
        duis. Tellus aliquam, eu erat amet, sit sit facilisis leo. Est quisque
        netus phasellus turpis. Dignissim suspendisse lacus etiam sit vel sit
        libero ornare augue. Urna, consectetur malesuada ornare fermentum hac.
        Dignissim aliquam, tellus ultrices cursus congue. Aliquet pharetra nunc
        mollis scelerisque purus maecenas at at. Facilisi auctor arcu eu, arcu
        ultrices sit nunc congue. Etiam lectus purus quam nisi, interdum
        facilisis ut. Viverra non hendrerit quisque ligula ut vestibulum, odio
        eget cras. Nullam quis massa lectus amet, erat amet maecenas. Nunc
        vestibulum dictum lacus, eros nibh facilisis. Morbi urna pellentesque
        commodo ac vel porta turpis quis. Nunc, volutpat non facilisis
        sollicitudin sapien. Quis viverra tempus eu ullamcorper pulvinar odio
        cursus ante est. Integer euismod lectus tortor tincidunt tristique.
        Turpis rutrum odio viverra amet senectus. Eleifend ullamcorper cras
        felis erat elit mauris. Eget egestas consectetur ultrices a id nunc et.
        Aenean amet mi fermentum quam pulvinar lacus euismod ullamcorper. Tortor
        ut consectetur a venenatis tellus risus, enim. Facilisis consequat sed
        nibh risus convallis at morbi commodo amet. Ut amet nisi, massa
        scelerisque eu amet, sociis arcu blandit. Ornare at pellentesque non
        mauris. Nisl mattis dui eu non nulla parturient ac. Volutpat nec
        bibendum ultricies et lobortis ipsum nunc maecenas nisl. Imperdiet
        convallis elementum leo nisl. Cursus magna ullamcorper.
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;
