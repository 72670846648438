import * as React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Avatar, Badge, Stack, Typography } from "@mui/material";
import style from "themes/styles";
import { withStyles } from "@mui/styles";

const FabCustom = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
  height: "24px",
  width: "24px",
  borderRadius: "50%",
  fontSize: "12px",
  marginTop: 0,
}));
const badgeStyle = {
  "& .MuiBadge-badge": {
    color: "yellow",
    backgroundColor: "#27AE60",
    border: "2.58px solid white",
    right: `6px`,
    top: "11px",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
  },
};

const MessageCard = (props) => {
  const { name, msg, time, numberOfMsg, img, status, classes } = props;
  return (
    <Stack
      my={2}
      direction="row"
      spacing={2}
      alignItems={"center"}
      sx={{ cursor: "pointer" }}
    >
      <Badge
        sx={badgeStyle}
        color="primary"
        variant={status === "true" ? "dot" : null}
        badgeInset="14%"
      >
        <Avatar className={classes.rightbarAvatar} alt="John Sharp" src={img} />
      </Badge>
      <Stack width={"100%"} direction="row" justifyContent="space-between">
        <Stack justifyContent={"center"}>
          <Typography variant="body2" fontWeight={500}>
            {name}
          </Typography>
          <Typography variant="body1" className={classes.gradientText}>
            {msg}
          </Typography>
        </Stack>
        <Stack textAlign={"end"} alignItems={"end"} spacing={0.2}>
          <Typography
            variant="body1"
            color="gray.main"
            fontWeight={400}
            fontSize={13}
          >
            {time}
          </Typography>
          {numberOfMsg ? (
            <FabCustom style={{ fontSize: 12 }} color="primary">
              {numberOfMsg}
            </FabCustom>
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  );
};

MessageCard.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.any,
  msg: PropTypes.any,
  time: PropTypes.any,
  numberOfMsg: PropTypes.any,
  img: PropTypes.any,
  status: PropTypes.any,
};
export default withStyles(style)(MessageCard);
