import * as React from "react";
import PropTypes from "prop-types";
import style from "themes/styles";
import { withStyles } from "@mui/styles";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
  Box,
  Button,
  Stack,
} from "@mui/material";
import ProgressBar from "Components/ProgressBar";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbUpOffAltOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import { GET_PARAMS, GET_ENUMS } from "PopupRoutes/components/settings";
import usePrepareLink from "hooks/usePrepareLink";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

const HaveChildren = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const nextPage = usePrepareLink({
    query: {
      [GET_PARAMS.popup]: GET_ENUMS.popup.drink,
    },
  });
  const prvPage = usePrepareLink({
    query: {
      [GET_PARAMS.popup]: GET_ENUMS.popup.orginization,
    },
  });
  const HandleNext = () => {
    navigate(nextPage, { state: { title: false, close: false } });
  };

  const HandleBack = () => {
    navigate(prvPage);
  };

  const [yesOrNo, setYesOrNo] = React.useState(null);

  return (
    <>
      <DialogTitle>
        <ProgressBar value={65} />
      </DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <Box my={4}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => HandleNext()}
              variant="text"
              className={classes.skipBtn}
            >
              Skip
            </Button>
          </Box>
          <Typography
            variant="subtitle2"
            color="secondary"
            fontWeight="500"
            mb={1}
          >
            Lets setup your profile!
          </Typography>
          <Typography color="primary" variant="subtitle1" fontWeight="700">
            Do you have children?
          </Typography>
        </Box>
        <Stack justifyContent="center" direction="row" spacing={3} mb={5}>
          <Box>
            <Paper
              onClick={() => setYesOrNo("Yes")}
              className={classNames(
                classes.circleBox,
                yesOrNo === "Yes" && classes.gradientBtn
              )}
              elevation={3}
            >
              <ThumbUpOffAltOutlinedIcon sx={{ fontSize: 55 }} />
            </Paper>
            <Typography color="secondary" variant="caption">
              Yes
            </Typography>
          </Box>
          <Box>
            <Paper
              onClick={() => setYesOrNo("No")}
              className={classNames(
                classes.circleBox,
                yesOrNo === "No" && classes.gradientBtn
              )}
              elevation={3}
            >
              <ThumbDownOutlinedIcon sx={{ fontSize: 55 }} />
            </Paper>
            <Typography color="secondary" variant="caption">
              No
            </Typography>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack flex={1} justifyContent="space-between" direction="row">
          <Button onClick={() => HandleBack()} className={classes.backBtn}>
            Back
          </Button>
          <Button onClick={() => HandleNext()} className={classes.nextBtn}>
            Next
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};

HaveChildren.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(HaveChildren);
