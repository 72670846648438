import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import style from "themes/styles";
import { styled } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import AddCardModal from "./AddCardModal";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 5,
  width: 18,
  height: 18,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 2px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 2px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#ffffff",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px solid #C4C4C4",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "transparent",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
  "&:before": {
    display: "block",
    width: 4,
    height: 4,
    padding: "4px",
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    background: "#fffff",
  },
});

function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}
const Payment = (props) => {
  const [openModal, setOpenModal] = React.useState(false);
  return (
    <Grid container>
      <Grid item xs={12} sm={5}>
        <Paper
          sx={{
            p: 2,
          }}
        >
          <Typography
            variant="subtitle1"
            fontWeight={600}
            color="primary.dark2"
          >
            Payment
          </Typography>
          <Typography variant="body1">Select a Payment method</Typography>
          <Divider sx={{ margin: "14px 0px 0px 0px" }} />
          <Stack sx={{ maxWidth: "500px" }} mb={1}>
            <Typography
              variant="body2"
              color="primary.dark2"
              p={1}
              fontWeight={400}
            >
              Pay with App
            </Typography>
            <Stack
              sx={{ padding: "5px" }}
              direction={"row"}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <img
                  style={{
                    height: 42,
                    width: 42,
                    marginRight: "10px",
                  }}
                  alt="The house from the offer."
                  src="https://pbs.twimg.com/profile_images/1357660674034307072/joI-ag9h_400x400.jpg"
                />
                <Typography variant="body2" color="primary.dark2">
                  Gpay
                </Typography>
              </Box>
              <BpCheckbox />
            </Stack>
          </Stack>
          <Divider />

          <Stack sx={{ maxWidth: "500px" }} mb={5} direction={"column"}>
            <Typography
              variant="body2"
              color="primary.dark2"
              p={1}
              fontWeight={400}
            >
              Debit/Credit Card
            </Typography>
            <Stack
              sx={{ padding: "5px" }}
              direction={"row"}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <img
                  style={{
                    height: 20,
                    width: 40,
                    marginRight: "10px",
                  }}
                  alt="The house from the offer."
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Visa_Inc._logo.svg/2560px-Visa_Inc._logo.svg.png"
                />
                <Typography variant="body1" color="primary.dark2">
                  ******12345
                </Typography>
              </Box>
              <BpCheckbox />
            </Stack>
            <Stack
              sx={{ padding: "5px" }}
              direction={"row"}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <img
                  style={{
                    height: 30,
                    width: 40,
                    marginRight: "10px",
                  }}
                  alt="The house from the offer."
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Mastercard-logo.svg/772px-Mastercard-logo.svg.png"
                />
                <Typography variant="body1" color="primary.dark2">
                  ******12345
                </Typography>
              </Box>
              <BpCheckbox />
            </Stack>
            <Button sx={{ color: "#000" }} onClick={() => setOpenModal(true)}>
              <Box
                sx={{
                  height: 24,
                  width: 24,
                  marginRight: 1,
                }}
                component="img"
                alt="Add New Card"
                src="https://cdn-icons-png.flaticon.com/512/62/62910.png"
              />
              Add New Card
            </Button>
          </Stack>
          <AddCardModal
            modalopen={openModal}
            closeModal={() => setOpenModal(false)}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withStyles(style)(Payment);
