import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import style from "themes/styles";
import { withStyles } from "@mui/styles";
import DoneSharpIcon from "@mui/icons-material/DoneSharp";
import React from "react";

const Premium = (props) => {
  const { classes } = props;
  return (
    <Stack
      className={classes.planCard}
      alignItems={"center"}
      direction={"column"}
    >
      <Box className={classes.cardHeaderBtn}>Married</Box>
      <Stack
        sx={{ width: "100%" }}
        direction="column"
        alignItems="center"
        spacing={1}
      >
        <Stack direction={"row"} spacing={1} mt={2} alignItems={"center"}>
          <Typography variant="h6" color="primary" fontWeight={600}>
            12
          </Typography>
          <Typography variant="subtitle1" color="primary" fontWeight={400}>
            months
          </Typography>
          <Divider
            className={classes.dividerColor}
            orientation="vertical"
            flexItem
          />
          <Typography variant="body1" color="primary" fontWeight={400}>
            $6.00/mt
          </Typography>
        </Stack>
        <Divider className={classes.dashedDivider} sx={{ width: "100%" }} />
        <List sx={{ color: "#fff" }}>
          <ListItem>
            <ListItemIcon>
              <DoneSharpIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Unlimited likes" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DoneSharpIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="10 Super likes" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DoneSharpIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="5 locations at a time" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DoneSharpIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="All filters" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DoneSharpIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="View who liked you" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DoneSharpIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Review previous profile" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DoneSharpIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Review previous profile" />
          </ListItem>
        </List>
      </Stack>
      <Button
        href="/app/setting/plan/payment"
        sx={{
          maxWidth: "273px",
          height: "50px",
          marginBottom: "-25px",
          fontSize: "16px !important",
        }}
        className={classes.gradientBtn}
        fullWidth
      >
        Get 12 Month / $6.00
      </Button>
    </Stack>
  );
};
export default withStyles(style)(Premium);
