// project imports
//import config from 'config';

// action - state management
import * as actionTypes from "./actions";

export const initialState = {
  isLoggedIn: false, // for active default menu
  Data: {},
  AuthToken: "",
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_LOGIN:
      return {
        ...state,
        isLoggedIn: true,
        AuthToken: action.userData.AuthToken,
        Data: action.userData,
      };
    case actionTypes.SET_USER_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        AuthToken: "",
        Data: {},
      };
    default:
      return state;
  }
};

export default AuthReducer;
