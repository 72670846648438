import { Box, Button, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import style from "themes/styles";
import { withStyles } from "@mui/styles";
import React from "react";

const Boosters = (props) => {
  const { classes } = props;
  return (
    <Stack
      className={classes.planCard}
      alignItems={"center"}
      direction={"column"}
    >
      <Box className={classes.cardHeaderBtn}>Married</Box>
      <Stack
        direction="column"
        alignItems="center"
        spacing={1}
        sx={{ width: 1 }}
      >
        <Stack direction={"row"} spacing={1} mt={2} alignItems={"center"}>
          <Typography variant="h6" color="primary" fontWeight={600}>
            $2
          </Typography>
          <Divider
            className={classes.dividerColor}
            orientation="vertical"
            flexItem
          />
          <Typography variant="body1" color="primary" fontWeight={400}>
            $52 Likes/day
          </Typography>
        </Stack>
        <Divider className={classes.dashedDivider} sx={{ minWidth: "100%" }} />
      </Stack>
      <Button
        href="/app/setting/plan/payment"
        sx={{
          maxWidth: "273px",
          height: "50px",
          marginBottom: "-25px",
          fontSize: "16px !important",
        }}
        className={classes.gradientBtn}
        fullWidth
      >
        Get $2.00/50 Likes For Day
      </Button>
    </Stack>
  );
};
export default withStyles(style)(Boosters);
