import React from "react";
import PropTypes from "prop-types";
import styles from "../themes/styles";
import { withStyles, useTheme } from "@mui/styles";
import { Dialog, DialogTitle, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import useGetPopupState from "./components/hooks/useGetPopupState";
import popups from "./components/Popups";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { Box } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";

const GetParameterPopups = (props) => {
  const { classes } = props;
  const { state } = useLocation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { title, close } = state ? state : { close: false, title: false };
  const { mountedPopup, isOpened } = useGetPopupState();
  const Component = popups[mountedPopup];

  const navigate = useNavigate();
  const handleClose = () => {
    navigate(-1);
  };

  if (!Component) {
    return null;
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="xs"
      scroll={"paper"}
      className={classes.dialog}
      disableEscapeKeyDown
      open={isOpened}
    >
      {(title || close) && (
        <DialogTitle display={"flex"} justifyContent="space-between">
          <Box style={{ alignSelf: "flex-start" }}>
            {title ? mountedPopup : ""}
          </Box>
          {close && (
            <IconButton onClick={() => handleClose()}>
              <CloseOutlined />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <Component />
    </Dialog>
  );
};

GetParameterPopups.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GetParameterPopups);
