import * as React from "react";
import PropTypes from "prop-types";
import style from "themes/styles";
import { withStyles } from "@mui/styles";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ImageList,
  ImageListItem,
  Typography,
  Box,
  Button,
  Stack,
} from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import ProgressBar from "Components/ProgressBar";
import { GET_PARAMS, GET_ENUMS } from "PopupRoutes/components/settings";
import usePrepareLink from "hooks/usePrepareLink";
import { useNavigate } from "react-router-dom";

const itemData = [
  {
    img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    title: "Breakfast",
  },
  {
    img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    title: "Burger",
  },
  {
    img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
    title: "Camera",
  },
  {
    img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
    title: "Coffee",
  },
];

const AddPhoto = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const nextPage = usePrepareLink({
    query: {
      [GET_PARAMS.popup]: GET_ENUMS.popup.interest,
    },
  });
  const prvPage = usePrepareLink({
    query: {
      [GET_PARAMS.popup]: GET_ENUMS.popup.orientation,
    },
  });
  const HandleNext = () => {
    navigate(nextPage);
  };

  const HandleBack = () => {
    navigate(prvPage);
  };

  return (
    <>
      <DialogTitle>
        <ProgressBar value={35} />
      </DialogTitle>
      <DialogContent>
        <Box my={3} textAlign={"center"}>
          <Typography
            sx={{ maxWidth: "310px", margin: "0 auto" }}
            color="secondary"
            variant="subtitle2"
            fontWeight="500"
            mb={1}
          >
            Perfect, Now Lets Add Some Of Your Neat Photo’s
          </Typography>
        </Box>
        <Box mb={3}>
          <ImageList
            sx={{
              overflow: "inherit",
            }}
            cols={3}
            rowHeight={120}
          >
            {itemData.map((item, i) => (
              <Box key={"1000" + i} sx={{ position: "relative" }}>
                <ImageListItem
                  sx={{
                    overflow: "hidden",
                    borderRadius: "5px",
                    marginBottom: 2,
                  }}
                >
                  <img
                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
                <IconButton
                  className={classes.removeBtn}
                  aria-label="delete"
                  size="small"
                >
                  <CloseRoundedIcon
                    sx={{ fontSize: "10px", fontWeight: "500" }}
                  />
                </IconButton>
              </Box>
            ))}
            <IconButton
              className={classes.uploadBorder}
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input hidden accept="image/*" type="file" />
              <AddRoundedIcon className={classes.uploadIcon} />
            </IconButton>
          </ImageList>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack flex={1} justifyContent="space-between" direction="row">
          <Button onClick={() => HandleBack()} className={classes.backBtn}>
            Back
          </Button>
          <Button onClick={() => HandleNext()} className={classes.nextBtn}>
            Next
          </Button>
        </Stack>
      </DialogActions>
    </>
  );
};

AddPhoto.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(AddPhoto);
