import * as React from "react";
import { withStyles } from "@mui/styles";
import { Outlet, Route, Routes } from "react-router-dom";
import style from "themes/styles";
import SettingSidebar from "./SettingSidebar";
import PrivacyPolicy from "./PrivacyPolicy";
import AppSetting from "./AppSetting";
import UpgradePlan from "./UpgradePlan";
import { Box } from "@mui/material";
import Payment from "./Payment";

const SettingLayout = () => {
  return (
    <Box display={"flex"}>
      <SettingSidebar />
      <Box flex={1} p={2}>
        <Outlet />
      </Box>
    </Box>
  );
};

function Setting() {
  return (
    <Routes>
      <Route path="/" element={<SettingLayout />}>
        <Route path={"/"} element={<PrivacyPolicy />} />
        <Route path={"privacy-policy"} element={<PrivacyPolicy />} />
        <Route path={"app-setting"} element={<AppSetting />} />
        <Route path={"plan"} element={<UpgradePlan />} />
        <Route exact path={"plan"} element={<UpgradePlan />} />
        <Route path={"plan/payment"} element={<Payment />} />
      </Route>
    </Routes>
  );
}

export default withStyles(style)(Setting);
