import { GET_ENUMS } from "../settings";

import SignIn from "pages/Account/Login";
import About from "pages/Account/About";
import AddPhoto from "pages/Account/AddPhoto";
import Drink from "pages/Account/Drink";
import Education from "pages/Account/Education";
import EnterOtp from "pages/Account/EnterOtp";
import Ethnicity from "pages/Account/Ethnicity";
import Finish from "pages/Account/Finish";
import HaveChildren from "pages/Account/HaveChildren";
import Interest from "pages/Account/Interest";
import Orientation from "pages/Account/Orientation";
import ProfileSet from "pages/Account/ProfileSet";
import SelectGender from "pages/Account/SelectGender";
import SelectBirth from "pages/Account/SelectBirth";
import Realtionship from "pages/Account/Realtionship";
import Orginization from "pages/Account/Orginization";

const popups = {
  [GET_ENUMS.popup.signIn]: SignIn,
  [GET_ENUMS.popup.enterOtp]: EnterOtp,
  [GET_ENUMS.popup.name]: ProfileSet,
  [GET_ENUMS.popup.gender]: SelectGender,
  [GET_ENUMS.popup.dob]: SelectBirth,
  [GET_ENUMS.popup.ethnicity]: Ethnicity,
  [GET_ENUMS.popup.orientation]: Orientation,
  [GET_ENUMS.popup.photos]: AddPhoto,
  [GET_ENUMS.popup.interest]: Interest,
  [GET_ENUMS.popup.finish]: Finish,
  [GET_ENUMS.popup.relationship]: Realtionship,
  [GET_ENUMS.popup.about]: About,
  [GET_ENUMS.popup.education]: Education,
  [GET_ENUMS.popup.orginization]: Orginization,
  [GET_ENUMS.popup.children]: HaveChildren,
  [GET_ENUMS.popup.drink]: Drink,
};

export default popups;
