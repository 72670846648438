import * as React from "react";
import { withStyles } from "@mui/styles";
import style from "themes/styles";
import {
  Box,
  Drawer,
  TextField,
  Typography,
  List,
  ListItem,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Config from "themes/config";
const { drawerWidth } = Config;

function SettingSidebar(props) {
  const { pathname } = useLocation();

  console.log(pathname);

  const { classes, mobileOpen, handleDrawerToggle } = props;

  const isActive = (pname) => {
    return pathname === pname;
  };

  const drawer = (
    <List sx={{ mt: 8, p: 1 }}>
      <NavLink to="/app/setting/privacy-policy" className={classes.linkStyle}>
        <ListItem
          className={
            isActive("/app/setting/privacy-policy") || isActive("/app/setting")
              ? classes.activeBg
              : classes.inactiveBg
          }
        >
          <Typography color="primary.dark2">Privacy Policy</Typography>
          <ChevronRightIcon />
        </ListItem>
      </NavLink>
      <NavLink to="/app/setting/app-setting" className={classes.linkStyle}>
        <ListItem
          className={
            isActive("/app/setting/app-setting")
              ? classes.activeBg
              : classes.inactiveBg
          }
        >
          <Typography color="primary.dark2">App Settings</Typography>
          <ChevronRightIcon />
        </ListItem>
      </NavLink>
      <Box sx={{ ml: 3 }}>
        <Typography variant="body2" color="primary.dark2" sx={{ mt: 2 }}>
          Current Location
        </Typography>
        <TextField
          fullWidth
          sx={{ mt: 2, mb: 2 }}
          className={classes.textField}
          placeholder="Surat (Automatic)"
        />
        <Typography
          color="primary.dark2"
          variant="body2"
          sx={{ textDecoration: "underline", mb: 2 }}
        >
          +Add Other Location
        </Typography>
      </Box>
      <NavLink to="/app/setting/plan" className={classes.linkStyle}>
        <ListItem
          className={
            isActive("/app/setting/plan")
              ? classes.activeBg
              : classes.inactiveBg
          }
        >
          <Typography color="primary.dark2">Payment</Typography>
          <ChevronRightIcon />
        </ListItem>
      </NavLink>
    </List>
  );

  return (
    <Box>
      <Box
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      ></Box>
      <Box component="nav" sx={{ width: { sm: drawerWidth } }}>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          sx={{
            position: "fixed",
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="left"
          open={true}
        >
          <List sx={{ mt: 8, p: 1 }}>
            <NavLink
              to="/app/setting/privacy-policy"
              className={classes.linkStyle}
            >
              <ListItem
                className={
                  isActive("/app/setting/privacy-policy") ||
                  isActive("/app/setting")
                    ? classes.activeBg
                    : classes.inactiveBg
                }
              >
                <Typography color="primary.dark2">Privacy Policy</Typography>
                <ChevronRightIcon />
              </ListItem>
            </NavLink>
            <NavLink
              to="/app/setting/app-setting"
              className={classes.linkStyle}
            >
              <ListItem
                className={
                  isActive("/app/setting/app-setting")
                    ? classes.activeBg
                    : classes.inactiveBg
                }
              >
                <Typography color="primary.dark2">App Settings</Typography>
                <ChevronRightIcon />
              </ListItem>
            </NavLink>
            <Box sx={{ ml: 3 }}>
              <Typography variant="body2" color="primary.dark2" sx={{ mt: 2 }}>
                Current Location
              </Typography>
              <TextField
                fullWidth
                sx={{ mt: 2, mb: 2 }}
                className={classes.textField}
                placeholder="Surat (Automatic)"
              />
              <Typography
                color="primary.dark2"
                variant="body2"
                sx={{ textDecoration: "underline", mb: 2 }}
              >
                +Add Other Location
              </Typography>
            </Box>
            <NavLink to="/app/setting/plan" className={classes.linkStyle}>
              <ListItem
                className={
                  isActive("/app/setting/plan")
                    ? classes.activeBg
                    : classes.inactiveBg
                }
              >
                <Typography color="primary.dark2">Payment</Typography>
                <ChevronRightIcon />
              </ListItem>
            </NavLink>
          </List>
        </Drawer>
      </Box>
    </Box>
  );
}

export default withStyles(style)(SettingSidebar);
