import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import style from "themes/styles";
import { withStyles } from "@mui/styles";

const MyPlan = (props) => {
  const { classes } = props;

  const cancelPlan = () => {
    localStorage.setItem("plan", "");
    window.location.reload(false);
  };

  return (
    <Grid container mt={2}>
      <Grid item xs={12} sm={4} mb={4}>
        <Paper
          elevation={2}
          sx={{ p: 2, display: "flex", borderRadius: "10px" }}
        >
          <Stack flex={1} spacing={4}>
            <Box>
              <Typography
                variant="subtitle1"
                className={classes.gray}
                fontWeight={400}
              >
                Next billing date
              </Typography>
              <Typography>12/06/2022</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" color="gray" fontWeight={400}>
                Plan expire
              </Typography>
              <Typography variant="body1" color="gray">
                08/10/2022
              </Typography>
            </Box>
          </Stack>
          <Stack flex={1} alignItems="center">
            <Typography variant="body1">Basic plan </Typography>
            <Box
              mb={2}
              component="img"
              sx={{
                height: 72,
                width: 72,
              }}
              alt="The house from the offer."
              src="https://png.pngtree.com/png-vector/20201026/ourlarge/pngtree-gold-dollar-3d-coin-png-image_2377763.jpg"
            />
            <Typography variant="body1">$40/ month </Typography>
          </Stack>
        </Paper>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 3,
          }}
        >
          <Button
            onClick={() => cancelPlan()}
            className={classes.gradientBtn}
            sx={{ maxWidth: "45%", fontSize: "16px !important", maxHeight: 45 }}
          >
            Change Plan
          </Button>
          <Button
            onClick={() => cancelPlan()}
            className={classes.gradientBtn}
            sx={{
              maxWidth: "45%",
              fontSize: "16px !important",
              color: "#000",
              maxHeight: 45,
              textAlign: "center",
            }}
          >
            Cancel Plan
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default withStyles(style)(MyPlan);
