import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import userAuthReducer from './userAuthReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    authentication: userAuthReducer
});

export default reducer;
