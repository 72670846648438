import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import style from "themes/styles";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { Grid, InputBase, MenuItem, Stack, TextField } from "@mui/material";
import FeedImages from "./FeedImages.js";
import ManageImages from "./ManageImages";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    background: "linear-gradient(167.65deg, #121422 -12.82%, #02106D 144.95%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: 500,
    "&.Mui-selected": {
      fontWeight: 500,
      fontSize: 16,
      background: "linear-gradient(92.46deg, #0C59DB -3.95%, #03AACF 97.96%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

function ProfileTabs(props) {
  const { classes } = props;
  const [value, setValue] = React.useState(0);
  const [textCount, setTextCount] = React.useState("0");
  const [about, setAbout] = React.useState("");

  const GetCountText = (e) => {
    let TotalText = e.target.value.length;
    setTextCount(TotalText);
    setAbout(e.target.value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", mb: "15px" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <StyledTab label="Edit Profile" {...a11yProps(0)} />
          <StyledTab label="Feed" {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <Box sx={{ marginTop: "40px" }}>
        <TabPanel value={value} index={0} className={classes.tab}>
          <Typography
            variant="subtitle1"
            color="primary.dark2"
            fontWeight={500}
            mb={2}
          >
            Manage Photos
          </Typography>
          <ManageImages />
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                color="primary.dark2"
                fontWeight={500}
              >
                Name
              </Typography>
              <TextField fullWidth className={classes.textField} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                color="primary.dark2"
                fontWeight={500}
              >
                Username
              </Typography>
              <TextField fullWidth className={classes.textField} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                color="primary.dark2"
                fontWeight={500}
                sx={{ mb: 1 }}
              >
                Birthdate
              </Typography>
              <TextField fullWidth className={classes.textField} type="date" />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                color="primary.dark2"
                fontWeight={500}
                sx={{ mb: 1 }}
              >
                About Me
              </Typography>
              <InputBase
                fullWidth
                multiline
                minRows={4}
                maxRows={4}
                className={classes.commentBox}
                inputProps={{ maxLength: 50 }}
                placeholder="Write here about yourself......"
                onChange={(e) => GetCountText(e)}
              />
              <Typography
                sx={{ textAlign: "end" }}
                variant="body2"
                color="primary.dark2"
                fontWeight={400}
              >
                {textCount}/50
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack direction="row" gap={1}>
                <Box
                  component="img"
                  className={classes.uploadImg}
                  src="/images/profilePage_icon/children.png"
                />
                <Typography
                  variant="body1"
                  color="primary.dark2"
                  fontWeight={500}
                  sx={{ mb: 1 }}
                >
                  My ethnicity is:{" "}
                </Typography>
              </Stack>
              <TextField
                fullWidth
                className={classes.textField}
                placeholder="Hetrosexual"
                select
              >
                <MenuItem value="Hetrosexual">Hetrosexual</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack direction="row" gap={1}>
                <Box
                  component="img"
                  className={classes.uploadImg}
                  src="/images/profilePage_icon/children.png"
                />
                <Typography
                  variant="body1"
                  color="primary.dark2"
                  fontWeight={500}
                  sx={{ mb: 1 }}
                >
                  My sexual orientation is:
                </Typography>
              </Stack>
              <TextField
                fullWidth
                className={classes.textField}
                placeholder="African Americans"
                select
              >
                <MenuItem value="African Americans">African Americans</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack direction="row" gap={1}>
                <Box
                  component="img"
                  className={classes.uploadImg}
                  src="/images/profilePage_icon/children.png"
                />
                <Typography
                  variant="body1"
                  color="primary.dark2"
                  fontWeight={500}
                  sx={{ mb: 1 }}
                >
                  My ethnicity is:{" "}
                </Typography>
              </Stack>
              <TextField
                fullWidth
                className={classes.textField}
                placeholder="Hetrosexual"
                select
              >
                <MenuItem value="Hetrosexual">Hetrosexual</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack direction="row" gap={1}>
                <Box
                  component="img"
                  className={classes.uploadImg}
                  src="/images/profilePage_icon/children.png"
                />
                <Typography
                  variant="body1"
                  color="primary.dark2"
                  fontWeight={500}
                  sx={{ mb: 1 }}
                >
                  My sexual orientation is:
                </Typography>
              </Stack>
              <TextField
                fullWidth
                className={classes.textField}
                placeholder="African Americans"
                select
              >
                <MenuItem value="African Americans">African Americans</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack direction="row" gap={1}>
                <Box
                  component="img"
                  className={classes.uploadImg}
                  src="/images/profilePage_icon/children.png"
                />
                <Typography
                  variant="body1"
                  color="primary.dark2"
                  fontWeight={500}
                  sx={{ mb: 1 }}
                >
                  My ethnicity is:{" "}
                </Typography>
              </Stack>
              <TextField
                fullWidth
                className={classes.textField}
                placeholder="Hetrosexual"
                select
              >
                <MenuItem value="Hetrosexual">Hetrosexual</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack direction="row" gap={1}>
                <Box
                  component="img"
                  className={classes.uploadImg}
                  src="/images/profilePage_icon/children.png"
                />
                <Typography
                  variant="body1"
                  color="primary.dark2"
                  fontWeight={500}
                  sx={{ mb: 1 }}
                >
                  My sexual orientation is:
                </Typography>
              </Stack>
              <TextField
                fullWidth
                className={classes.textField}
                placeholder="African Americans"
                select
              >
                <MenuItem value="African Americans">African Americans</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack direction="row" gap={1}>
                <Box
                  component="img"
                  className={classes.uploadImg}
                  src="/images/profilePage_icon/children.png"
                />
                <Typography
                  variant="body1"
                  color="primary.dark2"
                  fontWeight={500}
                  sx={{ mb: 1 }}
                >
                  My ethnicity is:{" "}
                </Typography>
              </Stack>
              <TextField
                fullWidth
                className={classes.textField}
                placeholder="Hetrosexual"
                select
              >
                <MenuItem value="Hetrosexual">Hetrosexual</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack direction="row" gap={1}>
                <Box
                  component="img"
                  className={classes.uploadImg}
                  src="/images/profilePage_icon/children.png"
                />
                <Typography
                  variant="body1"
                  color="primary.dark2"
                  fontWeight={500}
                  sx={{ mb: 1 }}
                >
                  My sexual orientation is:
                </Typography>
              </Stack>
              <TextField
                fullWidth
                className={classes.textField}
                placeholder="African Americans"
                select
              >
                <MenuItem value="African Americans">African Americans</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Grid container p={3} spacing={2}>
            <Grid item xs={12} sm={4}>
              <Button
                href="/app/profile/preview-profile"
                className={classes.profilePrwBnt}
              >
                Preview
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                className={classes.gradientBtn}
                sx={{
                  maxHeight: "48px",
                  maxWidth: "307px",
                  fontSize: "14px !important",
                }}
              >
                Save Changes
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button className={classes.profilePrwBnt}>Discard Change</Button>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tab}>
          <FeedImages />
        </TabPanel>
      </Box>
    </Box>
  );
}
export default withStyles(style)(ProfileTabs);
