import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import themes from "./themes";
import { useSelector } from "react-redux";
import Routes from "./Routes";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { useLocation } from "react-router-dom";

function App() {
  let customization = useSelector((state) => state.customization);
  const { pathname } = useLocation();
  console.log(pathname);
  if (pathname === "/") {
    customization.themeName = "darkBlue";
  }
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <Routes />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
