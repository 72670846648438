import React from "react";
import PropTypes from "prop-types";
import ProfileSection from "./ProfileSection";
import NotificationSection from "./NotificationSection";
import Logo from "Components/Logo";
import { Box } from "@mui/material";
import MenuItems from "./MenuItems";
import themeConfig from "themes/config";
import { Link } from "react-router-dom";

const { drawerWidth } = themeConfig;
// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  return (
    <>
      <Box
        textAlign={"center"}
        sx={{
          width: drawerWidth,
          display: { xs: "none", sm: "block" },
        }}
      >
        <Link to="/app">
          <Logo />
        </Link>
      </Box>
      <MenuItems />
      {/* <SearchSection /> */}
      <NotificationSection />
      <ProfileSection />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
