import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Box } from "@mui/system";
import style from "themes/styles";
import { withStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useTheme } from "@mui/material/styles";
import useWindowDimensions from "utils/useWindowDimensions";

const itemData = [
  {
    img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    title: "Breakfast",
  },
  {
    img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    title: "Burger",
  },
  {
    img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
    title: "Camera",
  },
  {
    img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
    title: "Coffee",
  },
];

function ManageImages(props) {
  const { classes } = props;
  const theme = useTheme();
  console.log(theme.breakpoints.down("sm"));

  const { width } = useWindowDimensions();

  return (
    <ImageList
      sx={{
        gap: "16px !important",
        overflow: "inherit !important",
        marginBottom: "10px",
      }}
      cols={width < 599 ? 3 : 4}
    >
      {itemData.map((item) => (
        <Box sx={{ position: "relative" }}>
          <ImageListItem key={item.img} sx={{ borderRadius: "5px" }}>
            <img
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
          <IconButton
            className={classes.removeBtn}
            aria-label="delete"
            size="small"
          >
            <ClearIcon sx={{ fontSize: "16px" }} />
          </IconButton>
        </Box>
      ))}

      <ImageListItem>
        <img
          src={"/images/uploadImage.png"}
          alt={"Upload Image"}
          loading="lazy"
        />
      </ImageListItem>
    </ImageList>
  );
}

export default withStyles(style)(ManageImages);
