/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */

import React from "react";
import { useTheme } from "@mui/material/styles";

const ThemeTypography = (theme) => {
  const themes = useTheme();

  return {
    h1: {
      fontSize: "70px",
      lineHeight: "80px",
      fontWeight: 600,
      [themes.breakpoints.down("sm")]: {
        fontSize: "40px",
        fontWeight: 500,
        lineHeight: "50px",
      },
    },
    h2: {
      fontSize: "36px",
      fontWeight: 600,
    },
    h3: {
      fontSize: 18,
      lineHeight: 22,
      fontWeight: 500,
    },
    h6: {
      fontWeight: 600,
      fontSize: "24px",
    },
    body1: {
      fontSize: "14px",
      fontWeight: 400,
      [themes.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
    body2: {
      fontSize: "16px",
      fontWeight: 400,
      [themes.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    subtitle1: {
      fontSize: "18px",
      fontWeight: 400,
      [themes.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    subtitle2: {
      fontSize: "22px",
      fontWeight: 500,
      [themes.breakpoints.down("sm")]: {
        fontSize: "20px",
        fontWeight: 400,
      },
    },
    caption: {
      fontSize: "20px",
      fontWeight: 500,
    },
    span: {
      fontSize: "12px",
      fontWeight: 400,
    },
    /// for dashboard page
    mainContent: {
      backgroundColor: theme.palette.background.default,
      width: "100%",
      minHeight: "calc(100vh - 88px)",
      flexGrow: 1,
      padding: "20px",
      marginTop: "88px",
      marginRight: "20px",
      borderRadius: `${theme?.customization?.borderRadius}px`,
    },
    menuCaption: {
      fontSize: "1rem",
      fontWeight: 500,
      color: theme.palette.gray.main,
      textTransform: "capitalize",
    },
    subMenuCaption: {
      fontSize: "0.6875rem",
      fontWeight: 500,
      color: theme.palette.secondary.main,
      textTransform: "capitalize",
    },
    commonAvatar: {
      cursor: "pointer",
      borderRadius: "8px",
    },
    smallAvatar: {
      width: "22px",
      height: "22px",
      fontSize: "1rem",
    },
    mediumAvatar: {
      width: "34px",
      height: "34px",
      fontSize: "1.2rem",
    },
    largeAvatar: {
      width: "44px",
      height: "44px",
      fontSize: "1.5rem",
    },
  };
};

export default ThemeTypography;
